@media print {
  body {
    background-color: white;
  }
}

.dbadmin {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 100px;
}

.dbn-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-nav {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg-light);
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  height: calc(100vh - 50px);
}
.admin-dashboard {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr 0.8fr 1fr;
  width: 80%;
  min-width: 1100px;
  padding-top: 20px;
  margin-inline: auto;
  flex-wrap: wrap;
  gap: 12px;
}
.admin-selector {
  width: 200px;
  height: 200px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 30px;
  font-size: 16px;
  background-color: var(--color-bg-light);
  border-radius: 15px;
  color: white;
  font-weight: 800;
  gap: 10px;
  border: none;
}
.admin-selector:hover {
  filter: brightness(120%);
}
.admin-selector svg {
  width: 100%;
  height: 50px;
}
.new-payroll {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.new-payroll svg path {
  stroke: white;
}
.payroll-input-group {
  display: flex;
  flex-direction: column;
  width: min-content;
  min-width: 120px;
  padding-top: 5px;
  gap: 5px;
}
.payroll-input-group-wide {
  display: flex;
  flex-direction: column;
  width: min-content;
  width: 250px;
  padding-top: 5px;
  gap: 5px;
}

.payroll-input-group-shorter {
  min-width: unset;
  width: 100px;
}

.payroll-input-group-short {
  min-width: unset;
  width: 75px;
}

.payroll-header {
  width: 100%;
  padding-left: calc(10% + 10px);
  padding-right: calc(10% - 10px);
  box-sizing: border-box;
  color: white;
  display: flex;
  padding-bottom: 15px;
  margin-bottom: 20px;
  gap: 40px;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 49px;
  background-color: black;
  z-index: 80;
  align-self: flex-start;
  padding-top: 15px;
}
.payroll-row {
  display: block;
  color: white;
  font-weight: 600;
  padding-bottom: 30px;
  box-sizing: border-box;
  width: 80%;
  margin-left: calc(10% + 10px);
}

.input-text-payroll {
  color: white;
  font-weight: 500;
  font-size: 14px;
}
.payroll-dates {
  font-size: 16px;
}
.payroll-date-buttons {
  display: flex;
  width: 100px;
  gap: 10px;
}

.payroll-input-row {
  display: grid;
  grid-template-columns: 0.4fr 1fr auto;
  justify-content: space-between;
  gap: 15px;
  z-index: 10;
  position: relative;
  align-items: flex-end;
}
.row-employee {
  z-index: 10;
  position: relative;
  font-size: 25px;
}

.payroll-input-group:focus-within input {
  border: 1px solid var(--gold) !important;
}

.input-row {
  display: flex;
  align-items: center;
  gap: 10px;
  align-items: flex-end;
}
.input-row-center {
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.input-row-center::-webkit-scrollbar {
  display: none;
}

.reserve-select-payroll {
  background-color: var(--color-bg-light);
  color: white;
  text-align: right;
  font-size: 14px;
  padding: 8px;
  padding-left: 20px;
  opacity: 1;
}
.payroll-input-wrapper {
  position: relative;
  display: inline-block;
}
.payroll-input-wrapper::before {
  content: "$";
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-weight: 500;
  font-size: 14px;
}

.percent-input-wrapper {
  position: relative;
  display: inline-block;
}
.percent-input-wrapper::before {
  content: "%";
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-weight: 500;
  font-size: 18px;
}

.saved-payroll {
  color: white;
  width: 60.5%;
  max-width: 1000px;
  margin-inline: auto;
  border-radius: 8px;
}

.saved-payroll > div {
  background-color: var(--highlight) !important;
}
.payroll-pdf {
  font-size: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: white;
}

.employee-box {
  padding: 29px;
  padding-inline: 20px;
  border: 1px solid black;
}
.bold {
  font-weight: 800;
}
.right-row {
  display: flex;
  gap: 30px;
  align-items: flex-end;
  justify-content: space-between;
  align-content: center;
}
.right-row button {
  width: 60px;
}

.dbn-name-bigger {
  font-size: 1.3rem;
}

.employees {
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: white;
  font-weight: 600;
  padding-bottom: 30px;
  box-sizing: border-box;
  margin-inline: auto;
}
.employee-layout {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 80%;
  margin-inline: auto;
  padding-inline: 50px;
  box-sizing: border-box;
}
.employee-section-header {
  position: sticky;
  padding-top: 15px;
  padding-bottom: 15px;
  top: 49px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  padding-inline: 50px;
  margin-bottom: -15px;
  width: 80%;
  margin-inline: auto;
  background-color: #000000;
  box-sizing: border-box;
}
.employee-header-buttons {
  display: flex;
  gap: 20px;
}
.employee-row-subheader {
  font-weight: 800;
  font-size: 14px;
}
.employee-header-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--paper-white);
}
.ehb-unactive h2 {
  opacity: 0.5;
}
.employee-header-btn:hover {
  color: var(--gold);
}

.employee-row {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-light);
  padding: 30px 50px;
  border-radius: 5px;
  overflow-y: hidden;
}
.employee-row-focused {
  background-color: var(--highlight);
}
.employee-row-bigger {
  padding-bottom: 50px;
}

.employee-row-subsection {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.subsection-inputs {
  display: flex;
  gap: 10px;
}
.employee-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  grid-template-rows: 110px 110px;
  z-index: 15;
}
.employee-input-checkbox {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: var(--color-bg-light);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  padding: 8px;
  padding-bottom: 8.5px;
  color: var(--paper-white);
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}

.employee-input-checkbox:hover {
  background-color: var(--gold);
  border-color: var(--gold);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.employee-button-size {
  padding: 9px;
  padding-bottom: 9.5px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
}

.new-employee-btn {
  width: 40px;
  height: 40px;
  background-color: var(--gold);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.new-employee-btn:hover {
  filter: brightness(0.85);
}
.inactive-submit {
  cursor: unset;
  filter: brightness(0.5);
  background-color: var(--color-bg-light);
  border-color: var(--color-bg-light);
}
.inactive-submit:hover {
  cursor: unset;
  filter: brightness(0.5);
  background-color: var(--color-bg-light);
  border-color: var(--color-bg-light);
}
.body-row {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.employee-text {
  padding: 15px;
  border-radius: 10px 0px 0px 10px;
  background-color: var(--nice-black);
  flex-grow: 2;
  padding-left: 30px;
}

.employee-input {
  background: none;
  border: none;
  text-align: right;
  color: white;
  font-size: 18px;
  max-width: 120px;
  padding: 15px;
  border-radius: 0px 10px 10px 0px;
  margin-left: 1px;
  background-color: var(--nice-black);
  height: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.employee-input:focus {
  outline: none;
}

.body-row:focus-within {
  border: 1px solid var(--gold);
}

.save-button {
  color: white;
  background-color: var(--gold);
  border: 1px solid var(--gold);
  width: 50%;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.payroll-btn svg {
  filter: brightness(100);
}
.payroll-btn {
  padding: 0.5rem;
  width: 45px !important;
  height: 40px;
}

.row-background {
  position: absolute;
  background-color: var(--highlight);
  border-radius: 5px;
  height: 130px;
  width: 82%;
  margin-left: -1%;
  margin-top: -10px;
}

.row-background-employee {
  position: absolute;
  background-color: var(--highlight);
  border-radius: 5px;
  height: 140px;
  margin-left: -15px;
  z-index: 10;
  margin-top: -10px;
  width: 100vw;
  box-sizing: border-box;
}

.payroll-input-inactive {
  cursor: default;
  background-color: var(--color-bg-light);
}

.dash-wrapper {
  padding-top: 4rem;
  margin-inline: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dash-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 35px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
  border: 2px solid var(--border-color);
  background-color: var(--color-bg-light);
}
.dash-item-full {
  grid-column: span 2;
  height: fit-content;
}
.faux-dash-item {
  border-radius: 3px;
  width: 100%;
  height: 100%;
  padding: 30px 35px;
  position: relative;
  border: 2px solid var(--border-color);
  background-color: var(--color-bg-light);
  box-sizing: border-box;
}
.payroll-dash {
  position: relative;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  gap: 35px;
  box-sizing: content-box;
  align-items: center;
}
.res-dash {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.res-dash-left {
  display: flex;
  flex-direction: column;
}

.graph-wrapper {
  position: relative;
  width: 100%;
  height: 450px;
  border-radius: 3px;
  box-sizing: content-box;
  pointer-events: all !important;
}
.graph-wrapper svg {
  pointer-events: all !important;
}

.bar-wrapper {
  height: 53vh;
}

.bar-wrapper svg {
  z-index: 100;
  pointer-events: all !important;
}
.pointer-events svg {
  pointer-events: all !important;
}
.dash-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.giftcard-total {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-block: 5px;
  gap: 5px;
  width: fit-content;
  border-radius: 2px;
}
.small {
  font-size: 15px;
}

.graph-title {
  color: white;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  padding-bottom: 20px;
}

.dash-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 350px;
}

.old-payrolls-wrapper {
  color: white;
  height: 337px;
  overflow-y: scroll;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: black;
}

.old-payrolls {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: var(--paper-white);
  font-weight: 500;
  box-sizing: border-box;
}

.old-payroll {
  background-color: var(--color-bg-light);
  color: white;
  height: 50px !important;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
}

.old-payroll:hover {
  filter: brightness(120%);
}

.new-payroll {
  padding-inline: 10px;
  padding-block: 10px;
}
.new-payroll:hover {
  filter: brightness(0.95);
}

.edit-employees {
  background-color: var(--color-bg-light);
  filter: brightness(1);
}
.edit-employees:hover {
  background-color: var(--color-bg-light);
  filter: brightness(1.35);
}

.nav-back-button {
  background: none;
  border: none;
  border-radius: 3px;
  outline: none;
  padding: 12px;
  height: 46px;
  width: 50px;
  cursor: pointer;
}

.nav-back-button:hover {
  filter: brightness(80%);
}

.nav-back-hidden {
  opacity: 0;
  z-index: -100;
  cursor: unset;
}

.filter-button {
  background-color: var(--color-bg-light);
  color: var(--paper-white);
  padding: 2px 12px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.res-count-wrapper:hover .filter-button {
}

.res-count-wrapper:hover .connection-line {
}

.active-filter-button {
  filter: brightness(1.55);
}

.number-boxes {
  border-radius: 2px;
}
.number-boxes .res-amount-header {
  height: 30px;
}
.number-boxes div {
  border: none !important;
}
.admin-res-amount {
  padding: 2px 0px !important;
  box-shadow: none !important;
  background: none !important;
  color: var(--paper-white);
  width: min-content !important;
  gap: 5px !important;
}
.res-count-item {
  display: flex;
  flex-direction: column;
}
.res-dash-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}
.todays-res {
  padding-top: 25px;
  padding-bottom: 20px;
}
.res-count {
  color: var(--paper-white);
  border: 1px solid var(--border-color);
  padding: 25px 25px;
  border-radius: 2px;
  font-size: 20px;
  position: relative;
  z-index: 1;
}
.res-count svg path {
  stroke: var(--paper-white);
}

.res-count-label {
  font-size: 14px;
  opacity: 1;
  position: absolute;
  top: 0;
  margin-top: -13px;
  margin-left: -20px;
  background-color: var(--color-bg-light);
  padding: 3px 10px;
  border-radius: 2px 2px 0px 0px;
}

.tooltip {
  border-radius: 2px;
  padding-block: 5px;
  padding-inline: 8px;
  background-color: var(--black);
  font-size: 1rem;
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  position: relative;
  z-index: 25 !important;
}

.tooltip-cube {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.res-count-wrapper {
  position: relative;
}

.connection-line {
  position: absolute;
  right: 0;
  top: 50%;
  width: 50px;
  transform: translateX(98%);
  height: 2px;
  background-color: #4c4c4f;
  box-sizing: border-box;
  z-index: 1;
}

.connection-line::after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  height: 170px;
  margin-right: -2px;
  margin-top: -85px;
  border-radius: 0px;
  background: linear-gradient(to bottom, #313133 0%, #4c4c4f 50%, #313133 100%);
}

.connector-line {
  position: absolute;
  top: 0;
  right: 48%;
  width: 2px;
  background-color: #4c4c4f;
  box-sizing: border-box;
  z-index: 1;
  height: 16px;
  transform: translateY(-100%);
}

.cl-today {
  right: 16%;
}
.cl-week {
  right: 24%;
}
.cl-month {
  right: 35%;
}
.cl-year {
  right: 45%;
}
.cl-allTime {
  right: 56%;
}

.connector-line::before {
  content: "";
  position: absolute;
  right: 0;
  height: 2px;
  width: 170px;
  margin-top: 16px;
  transform: translateY(-100%) translateX(50%);
  border-radius: 0px;
  background: linear-gradient(to right, #313133 0%, #4c4c4f 50%, #313133 100%);
}
.connector-line::after {
  content: "";
  position: absolute;
  right: 0;
  height: 2px;
  width: 170px;
  transform: translateX(50%);
  border-radius: 0px;
  background: linear-gradient(to right, #313133 0%, #4c4c4f 50%, #313133 100%);
}

.new-block {
  border: none;
  cursor: pointer;
  margin-top: -15px;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  padding: 5px 6px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  gap: 5px;
  opacity: 0.8;
}

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.533);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: var(--color-bg-light);
  border: 2px solid var(--border-color);
  padding: 30px 35px;
  border-radius: 3px;
  color: white;
  box-sizing: border-box;
}

.time-block-form-body {
  display: flex;
  flex-direction: column;
  padding-block: 35px;
  padding-bottom: 45px;
  gap: 15px;
}

.time-block-form-body label {
  font-size: 16px;
}

.time-block-form-body select {
  width: 150px !important;
  margin-bottom: 0 !important;
}
.time-block-form-body input {
  margin-bottom: 0 !important;
}

.time-block-button {
  height: 60px;
  padding: 20px;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid var(--border-color);
  background: var(--color-bg-light);
  color: white;
  font-weight: 500 !important;
  font-size: 1rem;
  width: 150px;
  box-sizing: border-box;
  text-align: left;
  -webkit-appearance: initial;
}

.time-block-button:hover {
  filter: brightness(1.35);
}

.active-time-button {
  filter: brightness(1.75);
}

.active-time-button:hover {
  filter: brightness(1.75);
}

#repeat {
  accent-color: var(--gold);
}

.timeblock-container {
  padding-top: 25px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
  height: fit-content;
}
.timeblocks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0px;
  min-height: 100px;
  max-height: 50%;
  box-sizing: border-box;
}
.dot {
  width: 8px;
  height: 8px;
  background-color: var(--white);
  border-radius: 50px;
}

.timeblock {
  background-color: var(--color-bg-light);
  padding: 15px;
  padding-left: 20px;
}

.timeblock:hover {
  filter: brightness(1.35);
}

.no-inner-padding {
  padding-inline: 0px;
}
