.dashboard-navbar-wrapper {
  background-color: #000000;
  position: sticky;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid var(--border-color);
  z-index: 900;
}
.dashboard-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  padding-left: 10px;
  width: 80%;
  margin-left: 10%;
  height: 40px;
}

.dbn-name {
  color: var(--nice-white);
  font-weight: 600;
  font-size: 1.2rem;
}

.dbn-buttons {
  display: flex;
  gap: 0.5rem;
}

.dbn-button {
  cursor: pointer;
  background: none;
  color: white;
  border: none;
  padding: 15px;
  font-weight: 900;
  transition: 0.3s ease-in-out;
}

.dbn-button:hover {
  color: var(--gold);
}
.dbn-button-active {
  color: var(--gold);
}

@media (width <= 800px) {
  .dashboard-navbar-wrapper {
    display: none;
  }
}
