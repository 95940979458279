.background-color {
  width: 100%;
  height: 100vh;
  background-color: var(--order-background);
  padding-bottom: 50px;
  overflow: hidden;
}

.cart-button {
  right: 0;
  padding-block: 6px;
  padding-inline: 15px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 0s ease-in-out;
  background: var(--gold);
  border-radius: 3px;
  min-width: 33px;
  border: 1px solid var(--gold);
}

.empty-cart {
  background: var(--black);
  border: 1px solid var(--asdkfnr);
}

.order-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 80%;
  padding-top: 30px;
  padding-bottom: 45px;
  margin-inline: auto;
}

.sub-menu-header {
  color: white;
  font-weight: 800;
  font-size: 1.5rem;
  padding-bottom: 5px;
}

.order-container-sub {
  padding-top: 0 !important;
}
.sub-menu-container {
  padding-top: 15px;
}

.order-top {
  background-color: black;
  position: sticky;
  /*top: 97px;*/
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid rgba(250, 235, 215, 0.8);
}

.scroll-wrapper {
  overflow-x: auto;
}

.order-selectors {
  overflow: visible;
  box-sizing: border-box;
  white-space: nowrap;
  padding-block: 0.38rem;
  scrollbar-width: thin;
  width: fit-content;
  overflow-y: auto;
}

.cart-section {
  flex-shrink: 0;
  padding-top: 5.5px;
  padding-bottom: 6px;
  padding-right: 0px !important;
}

.order-selector {
  color: var(--nice-white);
  text-align: right;
  cursor: pointer;
  display: inline-block;
  padding-right: 1rem;
  font-size: 0.95rem;
  transition: all 0.2s ease-in-out;
}

.order-selector:hover {
  color: var(--gold);
}

.order-section {
  color: var(--nice-white);
  width: 100%;
  margin-bottom: 3rem;
  position: relative;
}

.order-section-header {
  font-weight: 800;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  position: sticky;
  top: -50px;
}

.section-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px 15px;
}

.item-container {
  box-sizing: border-box;
  position: relative;
  width: calc(33% - 10px);
  background-color: var(--paper-white);
  padding: 20px 20px 30px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  color: white;
  text-align: left;
  border: none;
  font-size: unset;
  transition: all 0.3s;
  color: var(--black);
  display: flex;
  flex-direction: column;
}

.item-modal {
  border-radius: 5px;
  min-width: 30%;
  max-width: 600px;
  transition: all 0.3 ease-in-out;
}

.inside-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.item-container:hover {
  transform: scale(1.005);
  box-shadow: var(--box-shadow);
}
.item-container-open:hover {
  transform: none;
  cursor: default;
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.item-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.item-closed-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  width: 100%;
}
.item-name {
  font-weight: 800;
  font-size: 1.125rem;
  color: var(--gold);
  align-self: baseline;
}
.item-info-left {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 85%;
}

.item-name-open {
  margin-top: -0.2rem;
  font-size: 1.5rem;
  text-wrap: balance;
}
.item-price {
  font-size: 1.2rem;
}
.item-desc {
  font-size: 0.9rem;
}
.item-desc-open {
  font-size: 1rem;
  width: 90%;
}
.item-section {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 2rem;
}

.item-subheader {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.req-input {
  transition: all 0.3s ease-in-out;
  width: 90%;
  resize: vertical;
  min-height: 5rem;
  max-height: 10rem;
  font: unset;
  font-size: 0.9rem;
  background: none;
  color: var(--black);
  border-radius: 5px;
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  box-sizing: border-box;
}
.req-input:focus {
  outline: none;
  border: 1px solid var(--border-color);
  filter: brightness(80%);
}

.item-footer {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: flex-end;
}

.qty-btn-container {
  display: flex;
  align-items: center;
  background: none;
  border-radius: 7px;
  border: 1px solid var(--border-color);
  color: var(--black);
  font-size: 1rem;
}
.qty-btn {
  padding: 10px 15px;
  font-size: 1rem;
  background: none;
  color: var(--black);
  cursor: pointer;
  border: none;
  transition: all 0.3s;
}
.qty-btn-dressing {
  padding: 10px;
}
.qty-btn-center-dressing {
  width: 1.9rem;
  text-align: center;
}

.qty-btn:hover {
  filter: brightness(120%);
}

.qty-left {
  border-radius: 7px 0px 0px 7px;
  border-right: 0.5px solid #ffffff90;
  margin-top: -2px;
}
.qty-btn-center {
  width: 2.5rem;
  text-align: center;
}
.qty-right {
  border-radius: 0px 7px 7px 0px;
  border-left: 0.5px solid #ffffff90;
  margin-top: -3px;
}

.close-btn {
  background: none;
  color: var(--black);
  transition: all 0.3s;
  font-weight: 900;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  align-self: flex-start;
}

.close-btn:hover {
  color: var(--dark-red);
  filter: brightness(80%);
}
.add-btn {
  background-color: var(--gold);
  border-radius: 7px;
  border: 2px solid var(--gold);
  font-size: 1rem;
  padding: 10px 15px;
  width: 10rem;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
  filter: brightness(110%);
}

.add-btn:hover {
  filter: brightness(100%);
}

.add-btn-disabled:hover {
  filter: none;
  border: 2px solid var(--gold);
  cursor: unset;
}
.item-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  gap: 10px;
  font-size: 0.8rem;
  padding-left: 0.2rem;
}

.option-btn {
  background: none;
  position: relative;
  border-radius: 7px;
  border: 1px solid var(--border-color);
  font-size: 0.9rem;
  padding: 15px 20px;
  color: var(--black);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: fit-content;
  min-width: calc(32.9% - 5px);
}

.option-btn-selected {
  border: 1px solid var(--gold);
  filter: brightness(80%);
  background-color: var(--gold);
  color: var(--paper-white);
  filter: brightness(110%);
}

.dressing-container {
  flex-grow: 2;
  width: max-content;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.dressing-label {
  position: absolute;
  margin-top: -2rem;
  font-size: 1rem;
}
.dressing-btn {
  width: 13rem;
  text-align: center;
}

.checkout-container {
  position: relative;
  padding-top: 40px;
  width: 70vw;
  margin-inline: auto;
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  gap: 1rem;
  min-height: calc(100vh - 150px);
}

.empty-container {
  min-height: calc(100vh - 150px - 4rem);
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  position: relative;
}

.back-button {
  background: none;
  border: none;
  outline: none;
  color: black;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  padding-left: 0;
}

.back-button:hover {
  transform: scale(1.03);
}
.mockline {
  height: 10px;
  margin-bottom: 10px;
}

.subheader {
  font-size: 0.85rem;
}
.order-type {
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
  padding: 15px;
  gap: 10px;
  border-radius: 5px;
  color: #000;
  justify-content: space-between;
}
.order-type-top {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 800;
  color: white;
  filter: invert(100%);
}
.order-type-column {
  display: flex;
  flex-direction: column;
}
.fee-text {
  font-size: 1rem;
  font-weight: 500;
}
.order-type-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.95rem;
  display: flex;
  flex-direction: column;
}

.order-type-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-block: 16px;
}
.order-type-btn svg {
  filter: invert(100%);
  pointer-events: none;
}

.inner-div,
svg {
  pointer-events: none;
}

.change {
  background: none;
  border: none;
  outline: none;
  color: var(--gold);
  font-weight: 700;
  cursor: pointer;
  align-self: center;
}

.reciept-container {
  box-sizing: border-box;
  background-repeat: repeat;
  background-color: var(--nice-white);
  border-radius: 2px;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-block: 2rem;
  height: fit-content;
  min-height: 300px;
  margin-bottom: 80px;
}

.checkout-variant {
  padding-inline: 3vw;
  padding-bottom: 3rem;
}

.reciept-line {
  height: 1px;
  background-color: var(--border-color);
}

.checkout-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  padding: 1rem;
}
.checkout-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.checkout-input {
  font: unset;
  font-size: 1rem;
  background-color: var(--black-hover);
  color: white;
  border-radius: 5px;
  border: 2px solid var(--black-hover);
  padding: 0.5rem;
  box-sizing: border-box;
}
.checkout-input:focus {
  outline: none;
  border: 2px solid var(--gold);
}

.checkout-items {
  background-color: var(--nice-white);
  border-radius: 3px 3px 0px 0px;
  padding-block: 1rem;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.checkout-item {
  display: flex;
  justify-content: space-between;
  gap: 0.3rem;
}
.checkout-item-left {
  flex-grow: 1;
}
.checkout-item-left-button {
  flex-grow: 1;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  background-color: var(--nice-white);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  padding: 0.4rem;
  color: #000;
}
.checkout-item-left-button:hover {
  filter: brightness(90%);
}

.cilb-d {
  cursor: unset;
}
.cilb-d:hover {
  filter: unset;
}
.checkout-item-header {
  display: flex;
  gap: 1vw;
  align-content: baseline;
  justify-content: space-between;
}
.row {
  display: flex;
  gap: 1rem;
  width: 100%;
}
.checkout-item-qty {
  width: 1rem;
  font-size: 1rem;
  text-align: center;
}
.checkout-item-name {
  flex-grow: 1;
  font-size: 1rem;
  color: var(--black);
}
.checkout-item-delete {
  font-weight: 500;
  display: inline;
  color: var(--black);
}
.item-price {
  justify-self: end;
  font-size: 0.9rem;
}
.checkout-line {
  width: 100%;
  height: 1px;
  background-color: var(--gold);
}
.checkout-prices {
  border-radius: 0px 0px 3px 3px;
  padding-top: 1rem;
  padding-bottom: 0.3rem;
  padding-left: 0.2rem;
  padding-right: 0.75rem;
  color: var(--black);
  display: flex;
  flex-direction: column;
  justify-self: center;
  gap: 5px;
}

.checkout-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  margin-inline: auto;
}

.cpb {
  font-weight: 600;
}

.delete-btn:hover {
  filter: brightness(95%);
}

.new-delete {
  width: 28px;
  height: 110px;
  max-height: 32px;
  border: none;
  background-color: var(--nice-white);
  cursor: pointer;
  border-radius: 5px;
  padding: 2.5px;
}
.new-delete:hover {
  filter: brightness(95%);
}

.left {
  text-align: left;
}
.checkout-header-row {
  display: flex;
  gap: 0.2rem;
  width: 100%;
  align-items: flex-start;
}

@media (width <= 1400px) {
  .checkout-container {
    width: 80%;
  }
}
@media (width <= 1200px) {
  .checkout-container {
    width: 90%;
  }
}
@media (width <= 1000px) {
  .order-top {
    padding-left: 4.4vw;
  }
  .cart-section {
    padding-right: 4vw !important;
  }
  .checkout-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-bottom: 70px;
    padding-top: 10px;
  }
  .checkout-items {
    padding-left: 10px;
    padding-right: 5px;
  }
  .reciept-container {
    padding: 2rem;
    margin-bottom: 0;
  }
  .back-button {
    margin-bottom: -10px !important;
  }
  .item-options {
    padding-left: 0.3rem;
  }
}
@media (width <= 800px) {
  .checkout-container {
    width: 80%;
  }
}

@media (width <= 500px) {
  .checkout-container {
    width: 90%;
  }
  .reciept-container {
    padding: 1rem;
  }
  .back-button {
    font-size: 0.9rem;
    margin-bottom: -10px !important;
    margin-left: -10px;
    transform: scale(0.95);
  }
  .back-button:hover {
    transform: scale(0.97);
  }
  .checkout-item-name {
    font-size: 0.9rem;
  }
}

@media (width <= 470px) {
  .order-selectors {
    font-size: 0.8rem;
  }
}

@media (width <= 420px) {
  .item-name-open {
    font-size: 1.2rem;
    margin-top: -0.1rem;
  }
  .close-btn {
    margin-right: -12px;
    padding-block: 10px;
    margin-top: -8px;
    border-radius: 5px;
  }
  .add-btn {
    min-width: 8rem;
    width: min-content;
    white-space: nowrap;
  }
  .item-footer {
    width: 100%;
  }
  .item-section {
    width: 100%;
  }
  .req-input {
    width: 100%;
    font-size: 0.8rem;
  }
  .item-desc-open {
    font-size: 0.85rem;
  }
  .item-subheader {
    font-size: 0.9rem;
  }
  .inside-modal {
    gap: 0rem;
  }
  .item-section {
    gap: 1.5rem;
  }
  .item-modal {
    width: 82%;
    margin-inline: auto;
  }
  .item-price {
    font-size: 1.1rem;
    margin-top: -1px;
  }
}

@media (width <= 400px) {
  .order-selector {
    font-size: 0.8rem;
  }
}

@media (width <= 1200px) {
  .item-container {
    width: calc(50% - 10px);
  }
}

@media (width <= 1000px) {
  .order-container {
    margin: unset;
    padding-inline: 4vw;
    width: unset;
    padding-top: 10px;
  }
  .back-button {
    margin-bottom: 0px;
  }
  .submit-button-secondary {
    margin-top: 0px;
  }
  .another-row {
    margin-top: -10px;
    align-items: flex-start;
  }
  .phone-checkbox {
    margin-top: 0 !important;
  }
  .checkout-prices {
    padding-right: 1rem;
    padding-left: 0.9rem;
  }
}

@media (width <= 780px) {
  .item-container {
    width: 100%;
  }
}

.order-type-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.checkout-inputs {
  margin-top: 30px;
}

.checkout-row {
  align-items: baseline;
}
.another-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.input-address {
  margin-top: 2px;
}

.tip-buttons {
  display: flex;
  gap: 5px;
}

.rb-c {
  flex-grow: 3;
  width: 25% !important;
  font-size: 16px;
}

.select-other {
  border-radius: 7px;
  width: 24%;
}

.tai {
  resize: none;
  font: default;
  font-family: default;
  font-size: default;
  height: 120px;
}

.empty-order {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-inline: auto;
  width: 100%;
  min-width: 700px;
}

.empty-order-subheader {
  margin-top: 5px;
  font-size: 1rem;
  width: fit-content;
  font-weight: 600;
  background: none;
  border: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: black;
}

.empty-order-subheader:hover {
  text-decoration: underline;
}

.is-deleting-buttons {
  display: flex;
  gap: 5px;
}

.order-status {
  margin-inline: auto;
  text-align: center;
}

.order-status-header {
  font-weight: 600;
}
.order-status-subheader {
  font-size: 1rem;
  margin-top: 0.5rem;
}
.order-items {
  width: 80%;
  margin-inline: auto;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.item-total {
  margin-top: 10px;
}
.address-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

@media (width <= 780px) {
  .empty-order {
    width: 100%;
    min-width: unset;
  }
  .order-items {
    width: 100%;
  }
  .rb-c {
    min-width: unset !important;
  }
}

.input-hidden {
  height: 0;
  overflow: hidden;
  z-index: -500;
  margin-top: -10px;
}
.white {
  color: white;
}

.reserve-button-invert {
  border-radius: 2px;
  background: white;
  font-weight: 800;
  padding: 0.8rem;
  font-size: 0.9rem;
  transition: all 0.3s ease-in-out;
  border: none;
  border: solid 1px white;
  flex: 1;
  cursor: pointer;
}

.rbi-inactive {
  filter: brightness(20%);
}

.reserve-button-invert:hover {
  background-color: var(--border-color);
  border: 1px solid var(--border-color);
}

.reserve-button-active {
  filter: brightness(110%);
  background: var(--gold);
  border: 1px solid var(--gold);
}

.reserve-button-active:hover {
  color: white;
  background-color: var(--gold);
  border: 1px solid var(--gold);
}

.reserve-select-invert {
  padding: 0.8rem;
  background: white;
  border: none;
  color: var(--light-black);
  border-radius: 2px 0px 0px 2px;
  font-weight: 800;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 0.9rem;
  border: solid 1px white;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-right: none;
  width: 5rem;
}
.reserve-select-invert:focus {
  outline: none;
  transition: all 0.3s ease-in-out;
  background-color: var(--border-color);
  border: 1px solid var(--border-color);
}

.order-header {
  background: black;
  color: white;
  font-weight: 700;
  font-size: 1.5rem;
  border: none;
}

.other-right {
  border-radius: 0px 2px 2px 0px;
}
.other-input {
  width: min-content;
  display: flex;
}
.tip-buttons {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  align-content: stretch;
  flex-wrap: wrap;
}

.rs-os {
  padding-bottom: 80px;
}

.disabled-type:hover {
  background: none;
  color: unset;
}

.no-change {
  transform: none !important;
  cursor: unset !important;
}
.no-change:hover {
  text-decoration: none;
}
.padding-bottom {
  padding-bottom: 10px;
}

.smallllll-text {
  font-size: 0.7rem;
}

.your-order {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 15px;
  height: fit-content;
}
.your-order-top {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}
.reciept-header {
  font-weight: 800;
}
.checkout-price {
  font-size: 0.9rem !important;
}

@media (max-width: 700px) {
  .checkout-page {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 2rem;
  }

  .your-order-container {
    order: -1; /* this will make 'your-order' appear first */
  }

  .checkout-side {
    order: 1; /* this ensures 'checkout-side' remains second */
  }
  .checkout-row {
    display: flex;
    flex-direction: column;
  }
  .checkout-items {
    padding-inline: 0px;
  }
  .checkout-prices {
    padding-inline: 0px;
  }
}
.no-top-line {
  min-height: calc(100vh - 125px);
}
.ord {
  margin-top: 1.1rem;
  font-size: 0.8rem;
}
