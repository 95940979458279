:root {
  --gold: #d3963add;
  --solid-gold: #b07c37;
  --new-gold: #d3a83a;
  --gold-2: #202020;
  --nice-white: rgb(248, 244, 241);
  --paper-white: #f8f4f1;
  --paper-white2: #f8f4f1c1;
  --gold-m: #f7deb505;
  --gold-m: #b7a18d10;
  --gold-m-fr-fr: #b99258;
  --gold-no: #dd9e3f;
  --orange-gold: #be7439;
  --menu-gold-1: #d21e1e;
  --testL: #cb5353;
  --menu-gold-2: #f50039;
  --light-red: #d21e1e;
  --red: #ad0f0f;
  --green: #008209;
  --dark-red: #ad1111;
  --nice-gray: rgb(10, 10, 10);
  --black: rgb(21, 19, 25);
  --dark-black: #1d1b28;
  --light-black: #444444;
  --border-color: #5b5b5b57;
  --subtle-border-color: #b6b6b6;
  --transparent-border-color: #b6b6b62c;
  --disabled: #808080;
  --more-disabled: #8080806f;
  --black-hover: #f8f2eb;
  --white: rgb(231, 231, 231);
  --black-text: #ecceb5;
  background-color: rgb(255, 255, 255);
  --asdkfnr: #faebd7;
  --color-bg-light: #1c1c1f;
  --color-bg-green-tint: hsl(148, 45%, 13%);
  --color-bg-green-tint-lighter: hsl(148, 45%, 17%);
  --color-bg-red-tint: hsla(0, 24%, 30%, 0.855);
  --dark-container-color: #1d1d1d;
  --dark-container-color-brightened: #313131;
  --box-shadow: 0 0 5px rgba(228, 228, 228, 0.1),
    0 0 10px rgba(210, 210, 210, 0.2) inset;
  --highlight: #323335;
  --light-blue: #9ac5f6;
  --dark-blue: #4c88ca;
  --admin-black: #161b22;
}

.clean-button {
  border: 1px solid var(--border-color);
  background: var(--paper-white);
  display: flex;
  justify-content: center;
  padding: 20px 30px;
  font-size: 1rem;
  gap: 10px;
  align-items: center;
  color: var(--color-bg-light) !important;
  border-radius: 5px;
}
.clean-button:hover {
  filter: brightness(95%);
}

#path1,
#path2 {
  fill: #ffffff00;
  stroke: white;
}

#path2 {
  will-change: fill;
  stroke-dasharray: 1663;
  stroke-dashoffset: 1663;
  stroke-width: 1px;
  animation: fillSvg 2s ease-in 1.8s forwards;
}

#w {
  will-change: stroke-dashoffset;
  stroke-dasharray: 379;
  stroke-dashoffset: 0;
  animation: drawW 1.5s ease-in-out 0.5s forwards;
  -webkit-transform: translateZ(0);
}
@keyframes drawW {
  from {
    stroke-dashoffset: 748;
  }
  to {
    stroke-dashoffset: 379;
  }
}

#e {
  will-change: stroke-dashoffset;
  stroke-dasharray: 148;
  stroke-dashoffset: 0;
  animation: drawE 0.8s ease-in-out 1.1s forwards;
  -webkit-transform: translateZ(0);
}
@keyframes drawE {
  from {
    stroke-dashoffset: 296;
  }
  to {
    stroke-dashoffset: 148;
  }
}

#l {
  will-change: stroke-dashoffset;
  stroke-dasharray: 106;
  stroke-dashoffset: 0;
  animation: drawL 0.7s ease-in-out 1.4s forwards;
  -webkit-transform: translateZ(0);
}
@keyframes drawL {
  from {
    stroke-dashoffset: 212;
  }
  to {
    stroke-dashoffset: 106;
  }
}

#c {
  will-change: stroke-dashoffset;
  stroke-dasharray: 118;
  stroke-dashoffset: 0;
  animation: drawC 0.7s ease-in-out 1.6s forwards;
  -webkit-transform: translateZ(0);
}
@keyframes drawC {
  from {
    stroke-dashoffset: 236;
  }
  to {
    stroke-dashoffset: 118;
  }
}

#o {
  will-change: stroke-dashoffset;
  stroke-dasharray: 126;
  stroke-dashoffset: 0;
  animation: drawO 0.7s ease-in-out 1.8s forwards;
  -webkit-transform: translateZ(0);
}
@keyframes drawO {
  from {
    stroke-dashoffset: 252;
  }
  to {
    stroke-dashoffset: 126;
  }
}

#m {
  will-change: stroke-dashoffset;
  stroke-dasharray: 284;
  stroke-dashoffset: 0;
  animation: drawM 1.2s ease-in-out 2s forwards;
  -webkit-transform: translateZ(0);
}
@keyframes drawM {
  from {
    stroke-dashoffset: 568;
  }
  to {
    stroke-dashoffset: 284;
  }
}

#e2 {
  will-change: stroke-dashoffset;
  stroke-dasharray: 147;
  stroke-dashoffset: 0;
  animation: drawE2 1.4s ease-in-out 2.2s forwards;
  -webkit-transform: translateZ(0);
}
@keyframes drawE2 {
  from {
    stroke-dashoffset: 294;
  }
  to {
    stroke-dashoffset: 147;
  }
}

@keyframes fillSvg {
  to {
    fill: white;
  }
}

h2 {
  font-size: 1.5rem;
  color: white;
  margin: 0px;
  font-weight: 600;
}
h3 {
  font-size: 1.1rem;
  color: white;
  font-weight: 500;
  margin: 0px;
}

.a-tag {
  color: white;
  font-size: 1.1rem;
  margin: 0px;
  line-height: 1.7rem;
  background: none;
  outline: none;
  border: none;
  padding: 0px;
}
.a-tag:hover {
  color: var(--gold);
}

a {
  text-decoration: underline;
  cursor: pointer;
}

p {
  color: white;
  font-size: 1.1rem;
  margin: 0px;
  line-height: 1.7rem;
}
.submit-button {
  padding: 0.85rem;
  background-color: var(--gold);
  filter: brightness(110%);
  border: none;
  border-radius: 2px;
  color: var(--black-hover);
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
}

@media (width <= 470px) {
  p,
  a {
    font-size: 1rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  .subtle-button {
    font-size: 1rem !important;
  }
}
html,
body {
  height: 100%;
  scrollbar-color: var(--gold) var(--black) !important;
  scrollbar-gutter: stable;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.3em;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: var(--gold);
  outline: none;
}

body {
  font-size: 1.125rem;
}
.fancy-text {
  font-size: 3rem;
  color: var(--nice-white);
  font-weight: 800;
}
.ft-small {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.swal-header {
  margin-bottom: -20px;
}

.page-container {
  position: relative;
  margin-top: 80px;
  width: 90%;
  margin-inline: auto;
}
.App-link {
  color: #61dafb;
}
.app {
  position: relative;
  background-color: var(--black);
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hl {
  height: 1px;
  width: 100%;
  background-color: var(--gold);
}
.gold {
  color: var(--gold) !important;
}

.left {
  text-align: left;
}
.inline {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-self: space-between;
  width: 40%;
}
.inline-baseline {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}

.button-main {
  height: 35px;
  background: none;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  transition: 0.3s;
  border: none;
  cursor: pointer;
  transition: all 0.23s linear;
  background-image: linear-gradient(var(--nice-white), var(--nice-white));
  background-repeat: no-repeat;
  background-size: 0px 0px, calc(100% - 0px) 100%;
  background-position: center bottom;
}

.button-main:hover {
  outline: none;
  background-size: 90% 2px, calc(100% - 50px) 100%;
  cursor: pointer;
  color: white;
}

.button-main:active {
  color: var(--nice-white);
  border-color: white;
  border: solid 1px;
}

.navbar {
  background-color: var(--black);
  border-bottom: 2px solid rgba(250, 235, 215, 0.8);
  position: sticky;
  height: 80px;
  top: 0;
  z-index: 1000;
}

.navbar-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding-inline: 4vw;
  height: auto;
  padding-block: 15px;
}

.navbar-links {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.navbar-logo {
  position: relative;
  height: 50px;
  filter: invert(15%) sepia(10%) saturate(4247%) hue-rotate(329deg)
    brightness(88%) contrast(87%);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.navbar-logo:hover {
  filter: invert(15%) sepia(10%) saturate(4247%) hue-rotate(329deg)
    brightness(88%) contrast(87%);
}

.navbar-logo-mobile {
  height: 30px;
  filter: invert(15%) sepia(10%) saturate(4247%) hue-rotate(329deg)
    brightness(88%) contrast(87%);
}

.navbar-link {
  color: var(--nice-white);
  cursor: pointer;
  font-size: 1.1rem;
  background-image: linear-gradient(var(--nice-white), var(--nice-white));
  background-repeat: no-repeat;
  background-size: 0px 0px, calc(100% - 0px) 100%;
  background-position: center bottom;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  margin-left: 0.75vw;
  margin-right: 0.75vw;
  z-index: 5;
  padding-top: 8px;
}

.navbar-link:hover {
  background-size: 90% 2px, calc(100% - 50px) 100%;
  cursor: pointer;
  color: white;
  transition: all 0.23s linear;
}

.navbar-link-active {
  background-image: linear-gradient(var(--gold), var(--gold));
  background-size: 90% 2px, calc(100% - 6px) 100%;
  transition: all 0.23s linear;
  cursor: pointer;
  color: white;
}

.navbar-link-active:hover {
  background-image: linear-gradient(var(--gold), var(--gold));
  background-size: 90% 2px, calc(100% - 6px) 100%;
}

.hamburger-button {
  align-self: center;
  position: relative;
  width: 57px;
  background: none;
  border: none;
  border-radius: 10px;
  display: flex;
  gap: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-top: 8px;
}

.hamburger-line {
  width: 80%;
  margin-left: 20%;
  height: 3px;
  background-color: antiquewhite;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
}
.ham-line-1 {
  transform: rotate(45deg) translateX(11.5px);
  margin-left: 0;
}
.ham-line-2 {
  width: 0px;
}
.ham-line-3 {
  transform: rotate(-45deg) translateX(11.5px);
  margin-left: 0;
}
.hamburger-button:hover .hamburger-line {
  filter: brightness(110%);
}

.mobile-nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 60px;
  right: 0;
  text-align: right;
  gap: 2rem;
  background: var(--black);
  padding-left: 2rem;
  padding-bottom: 2rem;
  padding-right: 4vw;
  padding-top: 0.2rem;
  border-radius: 0px 0px 0px 5px;
  transform: translateX(100%);
  transition: transform 0.2s ease-in-out;
  border-left: 2px solid var(--nice-white);
  border-bottom: 2px solid var(--nice-white);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.mobile-nav-active {
  transform: translateX(0%);
}

.footer-container {
  width: 100%;
  bottom: 0;
  overflow: none;
}

.footer {
  padding: 1rem;
  border-top: 2px solid var(--border-color);
  background-color: black;
  position: relative;
  z-index: 3;
}
.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 2rem;
}

.footer-link {
  color: antiquewhite;
  font-size: 0.6rem;
  font-weight: 500;
  transition: color 0.2s linear;
  cursor: pointer;
}

.footer-link:hover {
  color: var(--gold);
}

.vases {
  position: absolute;
  bottom: 0;
  right: 8vw;
  z-index: 99;
}

.bigger {
  font-size: clamp(0.8rem, 1.6vw, 1.9rem);
}

.giftcard-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
  gap: 10px;
}

.giftcard-button {
  padding: 0.8rem;
  min-width: 30%;
  flex-grow: 2;
  outline: none;
  border: 1px solid var(--border-color);
  border-radius: 7px;
  background-color: var(--nice-white);
  transition: all 0.3s ease-in-out;
  color: var(--black);
  cursor: pointer;
}

.giftcard-button:hover {
  filter: brightness(95%);
}
.giftcard-button-active {
  color: white;
  filter: brightness(110%);
  background-color: var(--gold) !important;
  border: 1px solid var(--gold) !important;
  box-shadow: 4px 6px rgba(0, 0, 0, 0.1);
}

.giftcard-button-active:hover {
  filter: brightness(100%);
}

.gc-bottom-left-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  height: 100%;
}

.gc-area {
  height: 11.1rem !important;
}

.giftcard-input {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
}

.home-header {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.home-pic-container {
  position: relative;
  top: 0;
}

.home-pic {
  position: sticky;
  width: 100%;
  height: 90vh;
  aspect-ratio: 2/1 auto;
}

.mid-pic-overlay {
  z-index: 10;
  position: relative;
  width: 100%;
  height: clamp(45vh, 40vw, 650px);
  overflow: hidden;
}

.mid-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
}

.after-mid-pic {
  background-color: #000000;
  width: 100%;
  height: 40px;
  border-bottom: 2px solid var(--border-color);
}

.pic-overlay {
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  text-align: center;
  color: var(--nice-white);
  border-radius: 1px;
  font-size: 3rem;
  font-weight: 800;
  transition: all 0.25s ease-in-out;
  background-size: 100% 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.pic-content {
  width: 523px;
  height: 168px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  will-change: filter;
  animation: shadow 1.2s ease-in-out 0.5s forwards;
}

@keyframes shadow {
  from {
    filter: drop-shadow();
  }
  to {
    filter: drop-shadow(0px 0px 32px #21212135)
      drop-shadow(0px 0px 16px #212121) drop-shadow(0px 0px 12px #000000);
  }
}

@media (width <= 800px) {
  .pic-content {
    width: 423px;
    margin-top: -100px;
  }
}

.scroll-down-wrapper {
  position: absolute;
  bottom: 0;
}

@media (width <= 400px) {
  .pic-content {
    width: 300px;
    margin-top: -100px;
  }
  .scroll-down-wrapper {
    position: absolute;
    bottom: 50px;
  }
}

@font-face {
  font-family: "Caxton Book BT";
  font-style: normal;
  font-weight: normal;
  src: local("Caxton Book BT"), url("CAXTON2.woff") format("woff");
}

@font-face {
  font-family: "Caxton Bold BT";
  font-style: normal;
  font-weight: normal;
  src: local("Caxton Bold BT"), url("caxton.woff") format("woff");
}

input {
  font-family: "Caxton Book BT";
  font-size: 14px;
  font-weight: 600 !important;
  color: var(--light-black);
}
textarea {
  font-family: "Caxton Book BT";
  font-size: 14px;
  font-weight: 600 !important;
  color: var(--light-black);
}

.hero-text {
  font-size: 3.2rem;
  font-family: "Caxton Bold BT";
  color: var(--paper-white);
}

.welcome-buttons {
  position: relative;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 50px;
  filter: drop-shadow(0px 0px 4px #212121);
}

.review-wrapper {
  position: relative;
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
}

.review-container {
  display: flex;
  flex-direction: column;
  padding-block: 150px;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  margin-left: 10px;
  background-color: var(--black) !important;
  box-sizing: border-box;
}

.review-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 0 3px var(--gold), 0 0 0 20px var(--nice-white);
  background-color: var(--nice-white);
  border-radius: 0px;
  padding-block: 50px;
  padding-inline: 20px;
  width: 750px;
}

.review-box * {
  color: var(--gold);
}

.review-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 180px;
  overflow: hidden;
  width: 680px;
}

.review-arrow {
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.review-arrow svg path {
  stroke: #000000;
}
.review-box:hover .review-arrow {
  opacity: 0.3;
}

.review-arrow:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.home {
  overflow: hidden;
}

.left-branch,
.right-branch {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  height: 300px;
  width: 200px;
  display: block;
  pointer-events: all;
  transition: all 0.3s ease-in-out;
}

.left-branch {
  margin-right: -10%;
}
.right-branch {
  margin-left: -10%;
}

.left-branch:hover,
.right-branch:hover {
  transform: scale(1.05);
  filter: brightness(0.7);
}

.review {
  position: absolute;
  width: 500px;
  text-align: center;
  color: var(--light-black);
  font-size: 1rem;
}

@media (width <= 600px) {
  .hero-text {
    font-size: 2.6rem;
    font-family: "Caxton Bold BT";
  }
  .review-box {
    width: 100%;
  }

  .review-container {
    width: 100% !important;
    padding-block: 200px;
    overflow: hidden;
  }
  .review-wrapper {
    position: relative;
    width: 275px;
  }
  .review {
    width: 300px !important;
    font-size: 0.8rem;
  }
}

@keyframes fade-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
  }
}

@media (width <= 1300px) {
  .vases {
    width: 12vw;
  }
}

@media (width <= 1000px) {
  .fancy-text {
    font-size: 2rem;
  }
  .vases {
    width: 15vw;
    right: 3vw;
  }
  .mid-pic {
    height: 30vh;
  }
  .home-right {
    display: none !important;
  }
}

@media (width <= 800px) {
  .vases {
    width: 20vw;
    right: 2vw;
  }
}

@media (width <= 700px) {
  .footer-links {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding-left: 4vw;
    line-height: 0.9;
    padding-block: 1rem;
  }
  .vases {
    width: 25vw;
    right: 5vw;
  }
  .pic-overlay {
    font-size: 2rem;
  }
  .review-display-container {
    display: flex;
    flex-direction: row;
    height: 200px;
    width: 90%;
    margin-left: 4.5%;
  }
  .welcome-buttons {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .button-main {
    width: 50%;
    font-size: 1rem;
    padding: 14px;
  }
}

@media (width <= 470px) {
  body {
    font-size: 1rem;
  }
  .footer {
    padding-block: 0.5rem;
  }
  .footer-links {
    padding-left: 0.2rem;
  }
  .vases {
    width: 25vw;
    right: 2vw;
  }
  .pic-overlay {
    font-size: 1.5rem;
  }
  .review-display-container {
    height: 200px;
    width: 95%;
    margin-left: 2.5%;
  }
  .button-main {
    font-size: 0.9rem;
  }
  .emblem-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
}

@media (width <= 380px) {
  .fancy-text {
    font-size: 1.7rem;
  }
}

.mt-10 {
  margin-top: 15px;
}

.empty {
  height: calc(100vh - 97px - 53px);
}

.gold-highlight {
  background-color: var(--gold);
  display: inline;
}

.home-block {
  width: 100%;
  background-color: var(--black);
  z-index: 100;
  padding-inline: 10%;
  padding-top: clamp(10px, 3%, 50px);
  padding-bottom: clamp(100px, 8%, 200px);
  box-sizing: border-box;
}

.hb-even {
  padding-bottom: clamp(50px, 6%, 100px);
}

.home-flex {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: space-between;
}

.home-flex > div {
  width: 45%;
}

.home-left {
  display: flex;
  flex-direction: column;
  padding-top: 1;
  justify-content: space-around;
  gap: 70px;
}

.home-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.subtle-button {
  color: white;
  background: none;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0px 0px;
  padding-right: 20px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 500;
}

.subtle-button:hover {
  color: var(--gold);
}

.emblem-block {
  width: 100%;
  border-top: 2px solid var(--border-color);
  background-color: black;
  padding-block: 20px;
  padding-top: 26px;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-left: 20px;
}

.featured-block {
  border-top: 2px solid var(--border-color);
  background-color: black;
  padding-block: 32px;
}
.featured-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  align-items: center;
}
.featured-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 5px;
}
.eater-wrapper {
  width: clamp(130px, 20%, 250px);
  aspect-ratio: 50 / 21;
  position: relative;
  height: auto;
}
.eater-img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.article-text a {
  cursor: pointer;
  text-decoration: inherit;
  color: inherit;
  font-size: inherit !important;
  font-style: italic;
}
.article-text:hover {
  text-decoration: underline;
  text-decoration-color: #e90600;
  text-decoration-thickness: 2px;
}
.emblem-block div {
  width: 150px;
  height: 150px;
}

.hb-row {
  display: flex;
  justify-content: space-around;
}

.hb-row > div {
  width: 45%;
}

.time-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 76%;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

@media (width <= 1000px) {
  .home-flex {
    flex-wrap: wrap;
  }
  .home-flex div {
    width: 100%;
  }
  .home-block {
    padding-top: 18%;
    padding-bottom: 16%;
  }
  .hb-row {
    flex-wrap: wrap;
    gap: 30px;
  }
  .hb-row .details {
    width: 100%;
  }
  .time-div {
    width: 99%;
  }
  .button-row {
    padding-bottom: 80px;
  }
}

.top-line {
  border-top: 2px solid var(--border-color);
  z-index: 98;
  position: relative;
}

.selector {
  font-size: 1rem;
  text-align: right;
  cursor: pointer;
  position: relative;
  background: none;
  border: none;
  padding: 10px;
  color: white;
}

.selector-active {
  color: var(--gold);
  font-weight: bold;
}

.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  z-index: 1500;
}

.dashboard-wrapper {
  font-family: "Arial";
}

button {
  font-family: "Caxton Book BT";
}

.dashboard-wrapper button {
  font-family: "Arial";
}

.review-section h2 {
  position: absolute;
  text-align: center;
  margin-top: -200px;
}
@media (max-width: 600px) {
  .home-block {
    padding-inline: 5%;
  }
}

.more-padding {
  padding-block: 120px;
}

.faq--container {
  min-height: calc(110vh) !important;
}
.faq-section {
  padding-bottom: 150px !important;
}
.faqs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  width: 76%;
  padding-top: 20px;
}
.faq-topper {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}
.faq-container::after {
  content: "";
  bottom: 0;
  position: absolute;
  width: 100%;
  background-color: var(--border-color);
  height: 1px;
  overflow: hidden;
}

.faq-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  cursor: pointer;
  padding-block: 40px;
  overflow: hidden;
  box-sizing: border-box;
}

.faq-container * {
  color: var(--light-black) !important;
  text-align: left;
  user-select: none;
  position: relative;
}

.line {
  width: 2px;
  height: 20px;
  position: absolute;
  border-radius: 2px;
  background-color: var(--highlight);
  transition: all 0.4s ease-in-out;
}

.lhl {
  transform: rotate(90deg);
}

.faq-plus {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.faq-container:hover .faq-a {
  color: var(--gold) !important;
  transition: color 0.2s ease-in-out;
}

.faq-container:hover .faq-plus {
  opacity: 0.5;
  transition: all 0.2s ease-in-out;
}
.faq-plus-open {
  opacity: 0.5;
}

.faq-plus-open .vl {
  transform: rotate(135deg);
}
.faq-plus-open .lhl {
  transform: rotate(225deg);
}

.faq-a {
  user-select: none;
  font-family: "Caxton Bold BT";
  color: var(--paper-white);
  font-size: 1.125rem;
}

.deals {
  gap: 20px;
}

.deal-container {
  border: 1px solid #5b5b5b57;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 25px;
  gap: 45px;
}
.deal-svg > svg {
  width: 45px;
  height: 45px;
  stroke: #2d2d34;
}

.cal-svg {
  margin-left: 13px;
  margin-right: 5px;
}

.deal-texts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
  position: relative;
}
.deal-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
}
.deal-texts > .deal-text {
  width: 100% !important;
}

.deal-title {
  font-weight: 900;
}
.deal-text * {
  color: var(--light-black) !important;
}

.excluding-text {
  color: var(--light-black);
  margin-bottom: -20px;
  text-align: right;
  font-size: 14px;
}

@media (width <= 500px) {
  .faqs {
    width: 90%;
  }

  .faq-a {
    font-size: 1.2rem;
    font-weight: 800;
    user-select: none;
  }
  .faq--container {
    min-height: calc(105vh) !important;
  }
  .deal-container {
    padding: 25px;
    gap: 25px;
    width: 95%;
  }
  .deal-svg > svg {
    width: 50px;
    height: 50px;
    stroke: #2d2d34;
  }
  .cal-svg {
    margin-left: 0px;
  }
  .excluding-text {
    color: var(--light-black);
    margin-bottom: -20px;
  }
}

.award-wrapper #circle-r-ribbon {
  min-width: none;
  max-width: none;
  width: 200px !important;
  height: 200px !important;
  margin-left: -30px;
}

.award-wrapper #circle-r-ribbon a.r-ribbon_title {
  top: 84px;
  left: 28px;
}

.award-wrapper #circle-r-ribbon .r-ribbon_year {
  top: 49px !important;
  left: 22px !important;
}

.award-wrapper #circle-r-ribbon .r-ribbon_ahead-bottom svg {
  height: 140px !important;
}

.review-line {
  width: 250px !important;
}
.super-line {
  width: 450px;
  margin-top: 0px;
}

@media (width <= 500px) {
  .featured-top {
    gap: 15px;
  }
  .emblem-block {
    padding-left: 5px;
  }
  .award-wrapper {
    margin-left: 15px;
  }
  .review-container {
    width: 98%;
    margin-left: 0;
    margin-right: 0;
    margin-inline: auto;
  }
  .review-section {
    width: 100%;
    padding-top: 0;
    text-align: center;
  }
  .review-container .hero-text {
    font-size: 2.4rem;
    text-align: center;
  }
  .review-wrapper {
    margin-inline: auto;
    width: 400px;
    height: 100px;
  }
  .review-arrow {
    display: none;
  }
  .hero-text {
    font-size: 2.2rem;
  }
  .article-text {
    font-size: 1rem;
  }
  .faq-a {
    font-size: 1.1rem !important;
  }
  .line {
    height: 15px;
    margin-top: 3px;
  }
  .deal-svg > svg {
    width: 50px;
    margin-left: 5px;
    height: 40px;
  }
}

@media (width <= 400px) {
  .article-text {
    font-size: 1rem;
  }
  .review-wrapper {
    width: 100%;
  }
  .hero-text {
    font-size: 2rem !important;
  }
}

.popup-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  z-index: 101;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 15px;
}

@media (width >= 700px) {
  .popup-container-open {
    align-items: center;
  }
}

.popup-container-open {
  height: 100vh;
  margin-bottom: 0px;
  background-color: #00000050;
}

.popup-banner {
  width: clamp(300px, 20%, 400px);
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: var(--black);
  box-shadow: rgba(158, 158, 158, 0.25) 0px 0px 5px;
  border-radius: 3px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}
.popup-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: var(--white);
  cursor: pointer;
  font-weight: 1000;
  font-family: "Caxton Bold BT";
}
.popup-header svg {
  margin-top: 2px;
}
.popup-banner svg {
  fill: var(--white);
}

.popup-banner:hover {
  background-color: var(--paper-white);
}
.popup-banner .popup-header {
  font-size: 1.2rem;
}

.popup-banner:hover .popup-header {
  color: var(--dark-blue);
}
.popup-banner:hover svg {
  fill: var(--dark-blue);
}
.popup-banner-open .popup-header {
  color: var(--dark-blue);
  font-size: 1.7rem;
}

.popup-banner-open svg {
  fill: var(--dark-blue);
}

.popup-banner-open {
  background-color: var(--paper-white);
  color: var(--dark-blue);
  padding: 30px;
  width: 100%;
  max-width: 600px;
  position: relative;
  box-shadow: 5px 5px var(--dark-blue), -5px 5px var(--dark-blue),
    -5px -5px var(--dark-blue), 5px -5px var(--dark-blue);
  height: fit-content;
  bottom: 0;
}

.open-body {
  position: relative;
  z-index: 102;
}

.holiday-line {
  filter: invert(48%) sepia(87%) saturate(1242%) hue-rotate(190deg)
    brightness(89%) contrast(75%);
}

.hours {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 10px;
  color: var(--light-black);
  width: 70%;
  max-width: 320px;
  margin-inline: auto;
  padding-block: 15px;
  padding-bottom: 200px;
}

.date {
  text-align: left;
}

.time {
  text-align: right;
}

.holiday-scene {
  float: left;
  shape-outside: polygon(50% 0%, 100% 100%, 0% 100%);
  height: 0px;
  position: absolute;
  bottom: -11px;
  left: -100px;
  opacity: 0;
  z-index: 101;
}

.holiday-open {
  height: 375px;
  opacity: 1;
}

.holiday-close {
  position: absolute;
  right: 0;
  z-index: 105;
  top: 0;
  margin-right: 20px;
  margin-top: 20px;
  background: none;
  border: none;
  cursor: pointer;
  pointer-events: all;
}

.holiday-close svg path {
  stroke: var(--dark-blue);
  pointer-events: none;
}

.custom-shape-divider-bottom-1733340706 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.holiday-text {
  margin-top: -20px;
  padding-bottom: 40px;
}

.custom-shape-divider-bottom-1733342417 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1733342417 svg {
  position: relative;
  display: block;
  width: calc(154% + 1.3px);
  height: 152px;
}

.custom-shape-divider-bottom-1733342417 .shape-fill {
  fill: #9ac5f6;
}
