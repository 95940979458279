.reserve-container {
  position: relative;
  width: 60vw;
  min-height: calc(100vh - 100px);
  margin-inline: auto;
  display: flex;
  align-items: stretch;
}
.cancel-container {
  min-height: calc(100vh - 150px);
}
.reserve-top {
  position: sticky;
  z-index: 10;
  background-color: black;
  top: 82px;
  z-index: 100;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-block: 0.8rem;
  font-size: 0.95rem;
  color: var(--nice-white);
  border-bottom: 2px solid rgba(250, 235, 215, 0.8);
}

.reserve-text {
  font-size: 1rem;
  color: var(--menu-gold-1);
  align-self: flex-end;
  font-style: italic;
}

.reserve-section {
  box-sizing: border-box;
  background-repeat: repeat;
  background-color: var(--nice-white);
  border-radius: 2px;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-block: 3rem;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-inline: auto;
  border-radius: 1px;
  padding-bottom: 5rem;
  box-shadow: 3px 3px var(--gold), -3px 3px var(--gold), -3px -3px var(--gold),
    3px -3px var(--gold), 16px 16px var(--paper-white),
    -16px 16px var(--paper-white), -16px -16px var(--paper-white),
    16px -16px var(--paper-white);
}
.inResTable {
  width: 80%;
}
.giftcard-inputs {
  flex-direction: column;
  position: relative;
  justify-content: space-around;
  padding-inline: 10%;
  gap: 2rem;
  width: 50%;
  margin-inline: auto;
  padding-top: 20px;
}

.contact-inputs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  width: 50%;
  padding-top: 20px;
}

.contact-fname {
  grid-area: 1 / 1 / 2 / 2;
}
.contact-lname {
  grid-area: 1 / 2 / 2 / 3;
}
.contact-message {
  grid-area: 2 / 1 / 3 / 2;
}
.contact-submit {
  grid-area: 2 / 2 / 3 / 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.reserve-inputs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  justify-content: space-around;
  padding-inline: 5%;
  padding-top: 22px;
  padding-bottom: 20px;
  transition: all 1s ease-in-out;
  width: 65%;
  margin-inline: auto;
}

.other-times-wrapper {
  grid-area: 3 / 1 / 4 / 7;
  display: grid;
  grid-template-rows: 0fr;
  transition: all 0.7s;
  opacity: 0;
  border-radius: 5px;
  margin-bottom: -2rem;
}
.other-times-wrapper > .input-other-times {
  overflow: hidden;
}
.reserve-inputs-expanded > .other-times-wrapper {
  grid-template-rows: 1fr;
  opacity: 1;
  margin-bottom: unset;
}

.giftcard-inputs {
  grid-template-rows: 1fr;
}
.input-fname {
  grid-area: 1 / 1 / 2 / 4;
  margin-top: -2.5px;
}
.input-lname {
  grid-area: 1 / 4 / 2 / 7;
}
.input-size {
  grid-area: 2 / 1 / 3 / 3;
}
.input-date {
  grid-area: 2 / 3 / 3 / 5;
  margin-top: -2.5px;
}
.input-time {
  grid-area: 2 / 5 / 3 / 7;
}

.input-notes {
  grid-area: 4 / 1 / 5 / 4;
}
.input-email-submit {
  grid-area: 4 / 4 / 5 / 7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reserve-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
}

.reserve-select {
  padding: 0.7rem;
  background: none;
  border: 1px solid var(--border-color);
  color: var(--light-black);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  font-weight: 600;
}
.disabled-select {
  color: var(--disabled);
  border: 2px solid var(--disabled);
  cursor: default;
}
.disabled {
  color: var(--disabled) !important;
}
.reserve-button {
  width: auto;
  flex-basis: content;
  min-width: unset;
  border-radius: 7px;
  background-color: var(--nice-white);
  border: 1px solid var(--border-color);
  padding: 0.8rem;
  flex-grow: 2;
  transition: all 0.3s ease-in-out;
  color: black;
  cursor: pointer;
}

.reserve-button:hover {
  filter: brightness(95%);
}

.reserve-button-active {
  color: white;
  filter: brightness(110%);
  background: var(--gold);
  border: 1px solid var(--gold);
}

.reserve-button-active:hover {
  filter: brightness(100%);
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  20% {
    margin-left: 0.1rem;
  }
  40% {
    margin-left: -0.1rem;
  }
  60% {
    margin-left: 0.05rem;
  }
  80% {
    margin-left: -0.05rem;
  }
  100% {
    margin-left: 0rem;
  }
}

.reserve-select-error {
  animation: shake 0.5s ease-in-out 0s;
  color: var(--red);
  border: 1px solid var(--red);
}

.reserve-select:focus {
  outline: none;
  box-shadow: 4px 6px rgba(0, 0, 0, 0.1);
}

.input-text-error {
  color: var(--red) !important;
}

.input-group:focus-within .input-text {
  color: var(--gold);
}

.input-group {
  width: 100%;
  position: relative;
  text-align: left;
}
.input-text {
  color: var(--black);
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.1s ease-in-out;
}

.submit-button {
  padding: 0.85rem;
  background-color: var(--gold);
  filter: brightness(110%);
  border: none;
  border-radius: 2px;
  color: var(--black-hover);
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
}
.submit-button-secondary {
  color: var(--light-black);
  background: var(--paper-white-white);
  filter: brightness(160%);
}

.submit-button:hover {
  filter: brightness(100%);
}

.input-text-area {
  height: 8.1rem;
  resize: none;
}

.phone-checkbox {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.reserve-small-text {
  font-size: 0.9rem;
  color: var(--light-black);
  font-weight: 400;
}

.checkbox {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  background: none;
  outline: none;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
}

.checkbox-active {
  background-color: var(--gold);
  border: var(--gold);
}

.checkbox-active::before {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px; /* Adjust as needed */
  font-weight: 900;
  color: var(--nice-white); /* color of the checkmark */
}

.reserve-section-cancel {
  width: fit-content;
  max-width: 65%;
  padding-block: 2rem;
}
.fancy-line-cancel {
  width: 50%;
  margin-top: -40px;
}
.res-section-cancel {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 70%;
  margin-inline: auto;
  padding: 30px;
}

.res-to-cancel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 70%;
  margin-inline: auto;
}

.res-card {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: fit-content;
  gap: 15px;
  padding-top: 20px;
}

.res-header-text {
  color: var(--gold) !important;
}

.res-top-line {
  display: flex;
  align-items: baseline;
  gap: 20px;
}

.res-info {
  color: var(--light-black) !important;
  font-weight: 600;
}

.res-info-name {
  font-size: 2.5rem;
  font-weight: 800 !important;
}

.res-info-guests {
  font-size: 1.5rem;
  font-weight: 800 !important;
}

.res-info-time {
  font-size: 1.5rem;
  align-self: center;
}
.res-info-note {
  font-size: 1rem;
  align-self: center;
  text-align: center;
}

.res-buttons {
  padding-top: 70px;
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;
  align-items: baseline;
}
.res-buttons button {
  width: fit-content;
}

.modify-btn {
  color: var(--gold) !important;
  background: none;
  font-size: 24px;
  padding-block: 5px;
}
.save-modify-btn {
  color: var(--white) !important;
  font-size: 24px;
  padding-block: 5px;
}
.res-cancel-btn {
  background: none;
  color: var(--gold) !important;
  font-size: 16px;
  padding-block: 0px;
}

@media (width <= 1000px) {
  .res-to-cancel {
    width: 90%;
  }
  .reserve-section {
    width: 90%;
  }
  .reserve-top {
    top: 62px;
    padding-left: 4.4vw;
    justify-content: left;
  }
  .reserve-section {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 0rem;
    box-shadow: none;
    margin-inline: unset;
    margin-top: unset;
    padding-top: 50px;
    min-height: 82.3vh;
  }
  .reserve-container {
    width: 100%;
    margin: unset;
    padding-bottom: 0px;
    min-height: unset;
  }
}

@media (width <= 770px) {
  .reserve-section {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 0rem;
    box-shadow: none;
    margin-inline: unset;
    margin-top: unset;
    padding-top: 50px;
  }
  .reserve-container {
    width: 100%;
    min-height: 90vh;
    margin: unset;
    padding-bottom: 0px;
    min-height: unset;
  }
  .reserve-top {
    font-size: 0.8rem;
  }
  .reserve-inputs {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    min-width: unset;
    width: 90%;
  }
  .giftcard-inputs {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    width: 90%;
    box-sizing: border-box;
    padding-inline: 0;
  }
  .contact-inputs {
    width: 90%;
  }
  .phone-checkbox {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .res-to-cancel-info {
    font-size: 1rem;
  }
  .reserve-section-cancel {
    width: fit-content;
    max-width: unset;
    padding-block: 2rem;
  }
  .reserve-select {
    font-size: 16px;
  }
  .res-buttons {
    justify-content: center;
  }
  .res-top-line {
    padding-top: 30px;
  }
  .inResTable {
    width: 90%;
    padding-top: 30px;
  }
}

@media (width <= 470px) {
  .res-section-cancel {
    width: 85%;
    padding: 15px;
  }
  .res-to-cancel {
    width: 90%;
  }
  .reserve-small-text {
    font-size: 0.8rem;
    color: var(--light-black);
    font-weight: 400;
  }
  .phone-checkbox {
    margin-bottom: unset;
  }
  .reserve-top {
    font-size: 0.8rem;
  }
  .reserve-header {
    margin-bottom: 20px;
  }
  .reserve-inputs {
    gap: 2rem;
  }
  .giftcard-inputs {
    gap: 15px;
  }
  .reserve-text {
    font-size: 0.7rem;
  }
  .submit-button {
    margin-top: 1rem;
  }
  .input-text {
    font-size: 0.8rem;
  }
  .input-text-area {
    height: 5.1rem;
    min-height: 5.1rem;
    max-height: 10rem;
    resize: vertical;
  }
  .reserve-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: stretch;
    gap: 5px;
    box-sizing: border-box;
  }
  .reserve-button {
    padding: 0.8rem;
    min-width: 30%;
    flex-grow: 2;
    outline: none;
    transition: all 0.3s ease-in-out;
  }
  .input-fname {
    grid-area: unset;
  }
  .input-lname {
    grid-area: unset;
  }
  .input-size {
    grid-area: unset;
  }
  .input-date {
    grid-area: unset;
  }
  .input-time {
    grid-area: unset;
  }

  .input-notes {
    grid-area: unset;
  }
  .input-email-submit {
    grid-area: unset;
  }
}

.gc-area {
  resize: none;
}

.fuck-adobe {
  font-size: 0.8rem;
  width: 100% !important;
}
.fuck-adobe-focused {
  border-color: var(--gold);
  color: black;
}

.table-finder-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.res-container {
  padding-top: 100px;
  min-height: 100vh;
}

.res-box {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.res-box * {
  color: unset;
}
.table-finder {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: flex-end;
}

.res-info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.found-table {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-inline: 10px;
  box-sizing: border-box;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  font-size: 1rem;
}

@media (width <= 520px) {
  .table-finder {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .found-table {
    font-size: 0.9rem;
  }
}
.table-info {
  padding: 10px;
  padding-block: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-bg-light);
}
@media (width <= 470px) {
  .table-info {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
}
.dropdown {
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  height: 65px;
  box-sizing: border-box;
}

.dropdown-open {
  background-color: var(--nice-white);
  box-shadow: 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid var(--nice-white);
}

.dropdown-button {
  padding: 10px;
  padding-block: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--color-bg-light);
  background: none;
  border: none;
  width: 100%;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}

.date-picker {
  padding: 10px;
  padding-block: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--color-bg-light);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  width: 100%;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  height: 65px;
  transition: all 1s ease-in-out;
  box-sizing: border-box;
}

.date-picker-open {
  border-radius: 5px 5px 0px 0px;
  transition: all 1s ease-in-out;
  border-bottom: 1px solid var(--paper-white);
  box-shadow: 4px 8px rgba(0, 0, 0, 0.1);
  height: 65px;
}
.extended-button {
  position: absolute;
  height: 9.5px;
  margin-top: -1px;
  width: 100%;
  z-index: 150;
  background-color: var(--paper-white);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  box-sizing: border-box;
}
.faux-overlay {
  position: absolute;
  height: 2px;
  width: 100%;
  z-index: 50;
  margin-top: -1px;
  margin-left: -1px;
  background-color: var(--paper-white);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.faux-overlay-mobile {
  margin-top: -5px;
  height: 10px;
}

.dropdown-open .dropdown-button {
  border: none;
}

.dropdown-menu {
  scrollbar-color: var(--gold) var(--nice-white);
  background-color: var(--nice-white);
  position: absolute;
  left: -1px;
  width: 100%;
  max-height: 300px;
  scrollbar-width: 0px;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 30;
}

.dropdown-menu::-webkit-scrollbar-track {
  border-radius: 5px;
  color: var(--nice-white);
  box-shadow: none;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

.dropdown-open .dropdown-menu {
  box-shadow: 4px 8px rgba(0, 0, 0, 0.1);
  border-top: 1px solid var(--nice-white);
}
.dropdown-wrapper {
  border: 1px solid blueviolet;
}

.dropdown-item {
  border-radius: 0;
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
  font-weight: 500;
  text-align: center;
  margin-inline: auto;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 26px;
  color: var(--black);
  position: relative;
  margin: auto;
  font-size: 1rem;
  width: 100%;
}

.dropdown-item::before {
  content: "";
  position: absolute;
  height: 1px;
  background-color: var(--border-color);
  width: 50%;
  margin: auto;
  bottom: 0;
  left: calc(45% + 5px);
  transform: translateX(calc(-50% + 10px));
  opacity: 0.6;
}

.dropdown-item:last-child::before {
  content: none;
}

.dropdown-item-mobile::before {
  left: calc(50%);
}

.dropdown-item:hover {
  color: var(--gold);
}

.dropdown-header {
  font-size: 1.2rem;
  position: sticky;
  padding-inline: 20px;
  padding-block: 40px;
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.dropdown-header-button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
.dropdown-header-button:hover svg path {
  stroke: var(--gold);
}
.items-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  scrollbar-color: var(--gold) var(--nice-white);
}
.calendar-wrapper-enabled {
  position: absolute;
  width: 180%;
  height: fit-content;
  top: 94px;
  margin-top: 0px;
  padding: 10px 25px;
  padding-bottom: 20px;
  margin-left: -39.5%;
  background-color: var(--paper-white);
  box-sizing: border-box;
  border: 1px solid var(--border-color);
  color: var(--color-bg-light);
  box-shadow: 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 100;
}
.calendar-wrapper-disabled {
  overscroll-behavior: contain;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--paper-white);
  border: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (width <= 675px) {
  .dropdown-header {
    font-weight: 600;
  }
  .dropdown-header svg path {
    stroke-width: 2.5;
  }
  .mobile-menu {
    overscroll-behavior: contain;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid var(--border-color) !important;
    max-height: unset;
    width: 100%;
    background-color: var(--paper-white);
    z-index: 1002;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
  .overlay {
    overscroll-behavior: contain;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dropdown-item {
    padding-left: 10px;
    padding-top: 18px;
    padding-bottom: 30px;
    font-size: 1.1rem;
    width: 80%;
  }
  .dropdown-item::before {
    transform: translateX(-50%);
    width: 50%;
    bottom: 0;
  }
}

@media (width <= 470px) {
  .mobile {
    flex-direction: column;
  }
  .items-container {
    background-color: var(--paper-white);
    width: 100%;
  }
}

.no-scroll {
  overflow: hidden;
}

.edit-btn {
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: var(--color-bg-light);
  font-weight: 600;
}
.item-disabled {
  cursor: unset;
  text-align: center;
}
.item-disabled-mobile {
  padding-bottom: 40px;
}
.edit-btn:hover {
  color: var(--gold);
}
.flex-end {
  align-items: flex-end;
}

.br {
  height: 8px;
}
.hidden-select {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  pointer-events: none;
}

.table-finder-container {
  width: 90%;
  margin-inline: auto;
  margin-top: 1rem;
}

.reservation-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--nice-white);
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  background: rgb(248, 244, 241, 0.3);
  backdrop-filter: blur(6px);
}
.reservation-overlay-text {
  text-align: center;
  color: black;
  font-weight: 500;
  font-size: 16px;
}
.open-table-button {
  width: 100%;
  background-color: #d21e1e;
  color: rgb(255, 255, 255);
  outline: none;
  border: none;
  border-radius: 2px;
  width: fit-content;
  font-size: 16px;
  font-weight: 500;
  padding: 1rem;
  cursor: pointer;
}

.order-overlay {
  background: rgb(0, 0, 0, 0.8);
  height: 100vh;
  padding-bottom: 50px;
}
.order-overlay div {
  color: white;
}

@media (max-width: 770px) {
  .reservation-overlay div {
    margin-inline: 20px;
  }
}
.cal-header {
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 1.2rem;
  padding-inline: 20px;
  padding-block: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  box-sizing: border-box;
}

.calendar-wrapper-disabled {
  overscroll-behavior: contain;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  max-height: unset;
  width: 100%;
  background-color: var(--paper-white);
  z-index: 1002;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.p-datepicker-touch-ui {
  background-color: var(--paper-white);
  border: none;
  padding: 10px 25px;
  border-radius: 5px;
}

.p-component * {
  text-align: center;
  line-height: 1;
}
.p-calendar {
  width: 100%;
  z-index: 20;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.p-disabled {
  color: var(--more-disabled);
  pointer-events: none;
}
.p-datepicker-today span {
  border: 1.5px solid var(--gold);
  border-radius: 150px !important;
}

.p-datepicker-calendar tbody tr td span {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}
.p-datepicker-calendar tbody tr td span:hover {
  background-color: var(--gold);
  opacity: 80%;
  color: white;
}
.p-disabled td:hover {
  color: var(--disabled);
  filter: brightness(1.5);
  background: none;
}
.p-highlight {
  background-color: var(--gold);
  color: white;
}

.p-datepicker-other-month {
  opacity: 0;
}
.p-datepicker {
  overflow-x: hidden;
}

.p-datepicker table td {
  padding: 0.1rem;
}
.p-datapicker-header {
  position: fixed;
  top: 0;
}
.p-datepicker-group {
  height: max-content;
  box-sizing: border-box;
}
.p-datepicker-group-container {
  height: 100%;
  box-sizing: border-box;
}
.p-datepicker-calendar-container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-top: 15px;
}
.p-datepicker-calendar {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.p-datepicker-calendar * {
  font-size: 1rem !important;
  padding-top: 6px;
  padding-bottom: 6px;
}
.p-datepicker-calendar thead * {
  font-size: 1rem !important;
  font-weight: 800;
}
.p-datepicker-header {
  padding-top: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
  color: var(--light-black) !important;
}
.p-datepicker-title {
  display: flex;
  gap: 10px;
  pointer-events: none;
  color: var(--light-black) !important;
}
.p-datepicker-title button {
  font-size: 1rem !important;
  font-weight: 800;
  color: var(--light-black) !important;
}

.p-datepicker-next,
.p-datepicker-prev {
  background: none;
  border: none;
  padding: 8px 10px;
  color: var(--light-black) !important;
}

.p-datepicker-next:hover,
.p-datepicker-prev:hover {
  color: var(--gold);
}

.hidden-button {
  opacity: 0 !important;
  pointer-events: hidden !important;
}
.reserve-fail {
  text-align: center;
  font-size: 1rem;
  color: var(--red);
}

.calendar-wrapper-disabled .p-datepicker-calendar tbody tr td span {
  height: 2.5rem;
  width: 2.5rem;
}

.message-length {
  position: absolute;
  margin-top: -32px;
  margin-right: 5px;
  right: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  background-color: var(--paper-white);
  border-radius: 5px;
  padding: 5px;
  color: var(--light-black);
}

.shown {
  opacity: 1;
}
.exceeded {
  animation: shake 0.5s ease-in-out 0s;
  opacity: 1;
  color: var(--red);
}
.tai {
  padding-bottom: 2rem;
}

@media (width <= 500px) {
  .message-length {
    font-size: 14px;
  }
}
