.red {
  color: #d66c66;
  font-weight: 600;
}

.green {
  color: #52a452;
  font-weight: 600;
}

.red-border {
  border-color: #d27c77b3 !important;
}

.green-border {
  border-color: #52a452b3 !important;
}

.inhouse-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  background-color: black;
}

.hr-db-line {
  width: 1px;
  opacity: 0.2;
  background-color: var(--border-color);
}

.reservations-container {
  position: relative;
  color: white;
  padding-bottom: 100px;
  width: 93vw;
  max-width: 1200px;
  margin-inline: auto;
  box-sizing: border-box;
}

.res-container-inner {
  position: relative;
  width: fit-content;
  min-width: 95vw;
  max-width: 95vw;
  margin-inline: auto;
}
.orders-hide-overflow {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 15px;
  padding-bottom: 5rem;
  padding-right: 6px;
  padding-top: 2px;
}
.orders-container {
  color: white;
  transition: all 0.3 ease-in-out;
}

.order-container-state-true {
  width: 50%;
  transition: all 0.3s ease-in-out;
}
.order-container-state-false {
  width: 230px;
  transition: all 0.3s ease-in-out;
}

.order-container-state-true .orders-hide-overflow {
  overflow-y: hidden;
}
.orders-header {
  position: sticky;
  top: 81px;
  background-color: black;
  z-index: 100;
  padding: 25px;
  font-weight: 800;
  font-size: 1.5rem;
}

.reservations-header {
  position: sticky;
  top: 0;
  background-color: black;
  padding-top: 25px;
  padding-bottom: 25px;
  font-weight: 800;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  z-index: 150;
  width: 100%;
  padding-inline: 3%;
  box-sizing: border-box;
}

.reservations-header-mock {
  position: sticky;
  padding-top: 25px;
  font-weight: 800;
  display: flex;
  font: optional;
  gap: 3rem;
  align-items: center;
  width: 100%;
  margin-inline: auto;
}

.date-changer-container {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 3fr 1fr;
  border-radius: 5px;
  font-size: 1rem;
  background-color: var(--dark-container-color);
  border: 1px solid var(--border-color);
}

.date-changer-text {
  text-align: center;
  width: 165px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--dark-container-color);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.date-changer-text:hover {
  filter: brightness(120%);
}
.date-hider {
  position: fixed;
  z-index: -1;
  opacity: 0;
}
.reservations-header-text {
  font-size: 1.5rem;
  white-space: nowrap;
}

.date-changer-btn {
  background-color: var(--dark-container-color);
  border: none;
  color: white;
  font-size: 2rem;
  font-weight: 600;
  height: min-content;
  flex-grow: 1;
  padding-inline: 0px;
  cursor: pointer;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}
.dcbb {
  border-radius: 5px 0px 0px 5px;
}
.dcbf {
  border-radius: 0px 5px 5px 0px;
}
.date-changer-btn .svg {
  pointer-events: none;
}

.date-changer-btn:hover {
  filter: brightness(120%);
}

.reservations-displayer {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-inline: 3%;
}
.res {
  outline: none;
  border: none;
  color: white;
  padding: 14px 20px;
  border-radius: 2px;
  background-color: var(--dark-container-color);
  border: 1px solid var(--border-color);
  transition: background-color 0.3s ease-in-out, filter 0.3s ease-in-out;
  width: 100%;
  cursor: pointer;
  z-index: 2;
  font-size: 1.125rem;
  text-align: left;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.res-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 5;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.res-order {
  min-width: 300px;
}
.res:hover {
  filter: brightness(130%);
}
.res-true {
  background-color: var(--dark-container-color-brightened);
}
.res-true:hover {
  background-color: var(--dark-container-color-brightened);
}
.new-order {
  box-shadow: inset 0 0 0.5px 0.5px hsla(40, 55%, 70%, 0.2),
    /* 2. shadow ring 👇 */ 0 0 0.5px 0.5px hsla(40, 55%, 60%, 0.3),
    /* 3. multiple soft shadows 👇 */ 0 0 1px 1.5px hsla(40, 55%, 60%, 0.17),
    0 0 1.5px 2.5px hsla(40, 55%, 60%, 0.15),
    0 0 2px 3.5px hsla(50, 55%, 60%, 0.13);
  filter: none;
}

.res-true.new-order {
  background-color: #1a1a1a;
}

.new-order:hover {
  background-color: #1a1a1a;
  filter: none;
}

.waiting-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gold);
  width: 25px;
  height: 25px;
  border-radius: 100%;
  padding: 10px;
}
.res-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  gap: 1rem;
  height: 40px;
  width: 100%;
}
.res-amounts {
  display: flex;
  gap: 0.5rem;
  width: 160px;
}

.order-grid {
  display: flex;
  flex-direction: column;
}

.order-bottom {
  flex-direction: column;
}
.order-item-name {
  color: white;
}
.bottom-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: all 0.3s ease-in-out;
  margin-top: -60px;
  border-radius: 2px;
}
.bottom-wrapper .res-bottom {
  overflow: hidden;
}
.bottom-wrapper-true {
  grid-template-rows: 1fr;
  margin-top: -20px;
  opacity: 1;
}
.bottom-wrapper-false {
  grid-template-rows: 0fr;
  opacity: 0;
}
.res-bottom {
  position: relative;
  width: 100%;
  padding-top: 37px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  box-sizing: border-box;
  background-color: var(--dark-container-color);
  z-index: 1;
  border: 1px solid var(--border-color);
  border-radius: 2px;
}

.res-open-notes {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  padding-bottom: 5px;
  max-width: 90%;
  gap: 10px;
}
.order-notes-left {
  min-width: 60px;
  font-size: 0.9rem;
  align-self: flex-start;
  text-align: right;
}
.order-info {
  gap: 0.2rem;
  padding-top: 0.5rem;
  padding-bottom: 20px;
}

.no-padding {
  padding: 0;
}
.order-summary {
  display: flex;
  align-items: flex-start;
  width: 90%;
  gap: 1rem;
  padding-top: 20px;
}

.order-itemz {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.itemz-row {
  display: flex;
  gap: 1rem;
  width: 85%;
}
.order-summary-bottom {
  width: 90%;
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  gap: 0.2rem;
  padding-bottom: 20px;
}
.order-time {
  padding-top: 20px;
}
.order-time-text {
  padding-bottom: 20px;
}
.res-left {
  display: flex;
  gap: 1rem;
  padding-top: 0.3rem;
}
.res-arrived {
  background-color: var(--color-bg-green-tint);
}
.res-arrived.res-true {
  background-color: var(--color-bg-green-tint-lighter);
}
.res-cancelled {
  background-color: var(--color-bg-red-tint);
}

.res-noshow svg path {
  fill: var(--paper-white);
}

.res-name {
  padding-top: 5px;
  font-weight: 600;
  padding-bottom: 5px;
  font-size: 1.2rem;
  overflow: hidden;
  white-space: nowrap;
}

.feint-line {
  width: 95%;
  background-color: var(--border-color);
  opacity: 0.2;
  height: 2px;
}
.res-amount {
  font-weight: 800;
  padding: 10px 10px;
  font-size: 1.5rem;
  width: 25px;
  border-radius: 3px;
  text-align: center;
  display: flex;
}
.res-amount-header {
  background: var(--dark-container-color);
  display: flex;
  min-width: 65px;
  width: fit-content;
  justify-content: center;
  gap: 10px;
  font-size: 1.4rem;
  align-items: center;
  border: 1px solid var(--border-color) !important;
  height: 40px;
  box-sizing: border-box;
}

.res-right-side {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  min-width: 145px;
}

.order-right-side {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.res-btns {
  display: flex;
  gap: 20px;
  align-items: center;
}
.res-btn {
  background-color: var(--nice-black);
  opacity: 0.8;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  border: 1px solid var(--border-color);
  width: 3.5rem;
  height: 3.5rem;
  transition: all 0.3s ease-in-out;
}
.res-btn-mock {
  pointer-events: none;
  border: none;
}

.res-btn:hover {
  background-color: rgba(255, 255, 255, 0.081);
}

.checkmark {
  width: 30%;
  height: 35%;
  border-style: solid;
  border-width: 0 2px 2px 0;
  border-color: rgb(0, 112, 0);
  transform: rotate(45deg);
  position: relative;
}
.cancelled-res {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-inline: 3%;
  padding-top: 2rem;
}
.hl-db {
  width: 80%;
  background: linear-gradient(to right, var(--border-color), var(--black));
  height: 1px;
}

.cancelled-text {
  font-weight: 700;
}
.new-res-btn {
  border: none;
  outline: none;
  border-radius: 100px;
  cursor: pointer;
  min-width: 40px !important;
  width: 40px !important;
  height: 40px;
  max-width: 40px;
  margin-left: auto;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
}

.new-res-btn2 {
  padding: 0px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: var(--dark-container-color);
  border: 1px solid var(--border-color);
}

.new-res-btn:hover {
  filter: brightness(120%);
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 400;
  background-color: black;
  backdrop-filter: blur(5px);
  transition: all 0.3 ease-in-out;
  width: 100%;
  height: 100vh;
}

.new-res-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
  padding-inline: 10%;
  padding-top: 3%;
  padding-bottom: 10%;
  box-sizing: border-box;
  background-color: black;
  z-index: 20;
  overflow-y: scroll;
}

.res-modal-background {
  opacity: 0.5;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.res-modal-container {
  top: 0;
  left: 0;
  transform: translateX(10%) translateY(15%);
  width: 80%;
  height: fit-content;
  background-color: black;
  border: 1px solid var(--border-color);
  z-index: 951 !important;
  position: fixed;
  padding: 40px 40px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.res-modal-inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  width: 80%;
  margin-inline: auto;
}
.modal-input-group {
  display: flex;
  flex-direction: column;
}
.res-modal-input {
  padding: 10px 20px;
  border: 1px solid var(--border-color);
  background-color: var(--dark-container-color);
}

.new-res-top {
  display: flex;
  gap: 1rem;
}
.new-res-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 700;
  border: none !important;
  padding-block: 20px;
  padding-bottom: 10px;
}

.new-res-input::placeholder {
  color: white;
}

.new-res-input {
  height: 60px;
  padding: 20px;
  width: 100% !important;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid var(--border-color);
  background-color: var(--dark-container-color);
  color: white;
  font-weight: 500 !important;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  -webkit-appearance: initial;
  margin-bottom: 2rem;
}

.new-res-input:focus {
  border-color: #ffffff;
  outline: none;
}

.new-res-label {
  width: 50%;
}

.date-label {
  position: absolute;
  margin-bottom: -71.5px;
  margin-top: 16px;
  margin-left: 1rem;
  z-index: 100;
  font-size: 1rem;
  width: 80%;
  background-color: var(--dark-container-color);
  padding-block: 5px;
}

.submit-new-res {
  margin-top: 10px;
  margin-bottom: 20px;
  padding-block: 20px;
  font-size: 1.3rem;
}

.order-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.onehundred {
  width: 100%;
  padding-left: unset;
}

.ohc-apart {
  min-width: 400px;
  width: 100%;
  justify-content: space-between;
}

.status-symbol {
  height: 9px;
  width: 9px;
  border-radius: 10px;
}

.status-button {
  background: var(--dark-container-color);
  color: white;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  padding-inline: 10px;
  font-weight: 600;
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.1),
    /* 2. shadow ring 👇 */ 0 0 0 1px hsla(230, 13%, 9%, 0.075),
    /* 3. multiple soft shadows 👇 */ 0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
    0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
    0 3.5px 6px hsla(230, 13%, 9%, 0.09);
  cursor: pointer;
}

.status-button:hover {
  filter: brightness(120%);
}

.ss-red {
  background-color: var(--red);
}
.ss-green {
  background-color: var(--green);
}

.status-btn {
  border: none;
  outline: none;
  border-radius: 100px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: white;
  min-width: 40px !important;
  height: 40px;
  background: var(--dark-container-color);
  display: flex;
  width: fit-content;
  justify-content: center;
  gap: 10px;
  align-items: center;
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.1),
    /* 2. shadow ring 👇 */ 0 0 0 1px hsla(230, 13%, 9%, 0.075),
    /* 3. multiple soft shadows 👇 */ 0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
    0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
    0 3.5px 6px hsla(230, 13%, 9%, 0.09);
}
.left-margin {
  margin-left: 5px;
}

.status-btn:hover {
  filter: brightness(120%);
}
@media (width <= 800px) {
  .new-res-input {
    font-size: 1rem;
  }
}

.reservations-container {
  width: 100%;
}

@media (width = 500px) {
  .new-res {
    width: 100%;
  }
}

.order-footer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  width: 95%;
}

.cancel-button {
  position: relative;
  width: fit-content;
  align-self: flex-end;

  background-color: var(--nice-black);
  opacity: 0.8;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.1),
    0 0 0 1px hsla(230, 13%, 9%, 0.075), 0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
    0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
    0 3.5px 6px hsla(230, 13%, 9%, 0.09);
  padding: 10px;
}

.cancel-button:hover {
  background-color: rgba(255, 255, 255, 0.081);
}

.completed {
  background-color: var(--color-bg-green-tint);
  filter: brightness(150%);
}

.order-icons {
  display: flex;
  align-self: center;
  margin-left: auto;
  padding-right: 0.2rem;
  gap: 10px;
}

.order-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.not-paid {
  display: inline;
  font-weight: 400;
}

.gold {
  color: white;
}

.res-selfmade {
  box-shadow: -3px 0px var(--solid-gold) !important;
}

.text-area-res {
  height: 91px !important;
  resize: none;
}

.res-modal-header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shift-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.toggle-container {
  width: 160px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-color: var(--dark-container-color);
  border: 1px solid var(--border-color);
}

.toggle-labels-container {
  top: 0;
  left: 0;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.toggle-label {
  flex: 1;
  justify-self: center;
  padding: 10px 15px;
  font-size: 1rem;
  color: var(--paper-white);
  user-select: none;
}

.toggle {
  width: 80px;
  height: 40px;
  background-color: var(--gold);
  border: 5px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.arrived-time {
}

.new-res-inputs {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  justify-self: flex-start;
  background-color: black;
}

.new-res-input-group {
  border: 1px solid var(--border-color);
  padding: 20px;
  padding-top: 40px;
  padding-bottom: calc(40px - 2rem);
  border-radius: 10px;
  position: relative;
}

input[type="date"] {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.new-res-label {
  position: absolute;
  top: 0;
  background: black;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-top: -22px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.new-res-label svg path {
  stroke: var(--paper-white);
}

@media (width <= 500px) {
  .res-btn {
    height: 3rem;
    width: 3rem;
  }
  .reservations-container {
    padding-inline: 3%;
  }

  .date-changer-container {
    order: 0;
  }
  .res-amounts {
    order: 5;
    gap: 5px;
  }
  .shift-wrapper {
    order: 3;
  }
  .res-header {
    gap: 5px;
  }
  .res-amount-header {
    padding: 5px 5px;
  }
  .new-res-header {
    padding-bottom: 10px;
    padding-left: 5px;
    padding-top: 15px;
  }
  .new-res-form {
    padding-inline: 3%;
    border: none;
    width: 100%;
    border-radius: 0;
    padding-top: 0;
  }

  .reservations-header {
    padding-bottom: 40px;
  }
  .res-left {
    width: 70% !important;
    overflow: hidden;
    gap: 0.5rem;
    flex-grow: 0;
  }
  .res {
    padding: 10px 10px;
    padding-left: 5px;
    height: fit-content;
  }
  .res-bottom {
    padding: 10px 10px;
    padding-top: 40px;
    padding-left: 15px;
  }
  .res-amount {
    padding: 10px 5px;
  }
  .res-left-text {
  }
  .res-time {
    font-size: 1rem;
  }
  .arrived-time {
    font-size: 1rem;
  }
  .res-name {
    font-size: 1rem;
  }
  .res-arrived-time {
    height: 3rem;
  }
  .res-open-notes {
    font-size: 1rem;
  }
  .res-modal-container {
    top: 0;
    left: 0;
    transform: none;
    border-left: none;
    border-right: none;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding-top: 20px;
    padding-inline: 3%;
    box-sizing: border-box;
  }
}
.loading-button {
  pointer-events: none;
  cursor: unset;
}

.suggested-alternatives {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.suggested-alternatives button {
  background-color: var(--black);
  color: var(--paper-white);
  border: 1px solid var(--border-color);
  background-color: var(--dark-container-color);
  border-radius: 4px;
  height: 50px;
  font-size: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  padding-inline: 0px;
}

.suggested-alternatives button:hover {
  filter: brightness(120%);
}

.sug-alt {
  font-size: 1rem;
}
.alternatives {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
