.fixed {
  position: relative;
}
.menu-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 100px;
  width: 90vw;
  margin-inline: auto;
}

.menu-container-mobile {
  position: sticky;
  padding-bottom: 50px;
}

.menu-bottom {
  margin-top: 50px;
}

.menu-top {
  background-color: black;
  position: sticky;
  top: 82px;
  z-index: 100;
  border-bottom: 2px solid rgba(250, 235, 215, 0.8);
}

.menu-image {
  display: inline-block;
  width: 70%;
  padding-bottom: 10%;
  align-self: flex-end;
}
.menu-selectors {
  display: flex;
  gap: 3rem;
  font-size: 0.95rem;
  justify-content: center;
  padding-block: 0.8rem;
}

.fancy-line {
  display: flex;
  width: 30%;
  margin-inline: auto;
  margin-bottom: 20px;
}

.menu-selector {
  color: var(--nice-white);
  text-align: right;
  cursor: pointer;
  position: relative;
}
.lunch-groupings {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 5px;
}
.lunch-group {
  flex-shrink: 2;
  display: grid;
  grid-template-columns: 0fr;
  overflow: hidden;
  opacity: 0;
  transition: grid-template-columns 0.15s ease-in-out, opacity 0.15s ease-in-out;
  align-items: baseline;
  margin-left: -5px;
}

.lunch-group > div {
  overflow: hidden;
  color: var(--nice-white);
}

.lunch-group-active {
  grid-template-columns: 1fr;
  margin-left: 5px;
  opacity: 1;
}
.lunch-group-active > div {
  color: var(--gold) !important;
}
.special-text {
  font-style: italic;
  font-size: 0.8rem;
  height: 1rem;
  cursor: default !important;
}

.menu-selector:hover {
  color: var(--gold);
}

.menu-selector-active {
  color: var(--gold);
  font-weight: bold;
}

.menu-display {
  align-self: center;
  display: grid;
  grid-template-columns: masonry;
  grid-template-rows: repeat(4, 1fr);
  gap: 40px;
  width: 100%;
  outline: var(--red);
  color: white;
  height: auto;
  grid-area: 1 / 2 / 2 / 3;
  align-content: start;
  height: 80vh;
}
.menu-section {
  box-sizing: border-box;
  background-color: var(--nice-white);
  border-radius: 2px;
  padding-inline: 2vw;
  padding-block: 2rem;
  padding-bottom: 3rem;
  height: fit-content;
  line-height: 1.2;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
}

.menu-section-header {
  text-align: center;
  font-weight: 700;
  color: var(--white);
  font-size: 1.8rem;
  color: var(--gold);
  font-family: "Caxton Bold BT";
}

.menu-section-desc {
  text-align: center;
  width: 90%;
  padding-left: 5%;
  margin-top: 15px;
  margin-bottom: -10px;
}
.item-group-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.menu-item {
  text-transform: capitalize;
  color: var(--light-black);
  padding-top: 5px;
  font-weight: bold;
  text-align: center;
  width: 90%;
  margin-left: 5%;
}

.menu-item-desc {
  padding-left: 5px;
  font-style: italic;
  text-align: center;
  width: 80%;
  font-size: 1rem;
  padding-left: 10%;
  color: var(--light-black);
}

.menu-item-price {
  padding-left: 5px;
  text-align: center;
  width: 80%;
  font-size: 0.9rem;
  padding-top: 1px;
  padding-left: 10%;
  font-weight: 500;
  color: var(--light-black);
}

@media (width > 1000px) {
  .menu-container {
    position: relative;
    z-index: 2;
  }
}

@media (width <= 1000px) {
  .special-text {
    font-size: 0.7rem;
  }
  .fancy-line {
    width: 45%;
  }
  .menu-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 95vw;
    margin-inline: auto;
  }
  .menu-top {
    top: 62px;
    padding-left: 4.4vw;
  }
  .menu-bottom {
    margin-top: 2rem;
  }
  .menu-sidebar {
    flex-direction: row;
    align-items: baseline;
    gap: 20px;
    padding-left: 10vw;
    background-color: black;
    position: sticky;
    border-bottom: 1px solid var(--gold);
  }
  .menu-image {
    max-width: 30%;
  }
  .menu-selectors {
    justify-content: flex-start;
    gap: 1rem;
  }
  .menu-sidebar .hl {
    display: none;
  }
}

@media (width <= 420px) {
  .menu-selectors {
    font-size: 0.9rem;
  }
}
@media (width <= 400px) {
  .lunch-group-active {
    margin-left: 0px;
  }
  .menu-selectors {
    font-size: 0.75rem;
  }
  .fancy-line {
    width: 55%;
  }
  .menu-item-desc {
    font-size: 0.8rem;
  }
  .special-text {
    font-size: 0.6rem;
  }
}

.menu-bottom-bar {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-bottom: 40px;
}

@media (width <= 470px) {
  .menu-bottom-bar {
    flex-direction: row;
    width: 95%;
    gap: 10px;
    align-items: center;
    margin-inline: auto;
    padding-top: 20px;
  }
  .menu-bottom-bar button {
    width: 50%;
  }
  .menu-hero-text {
    padding-left: 15px;
  }
}

.menu-text {
  margin-top: -5px;
  margin-bottom: -15px;
}
